import React from "react";
import { Button, Col, Form, Image, Layout, Row } from 'antd';
import { TextField } from '@mui/material';

import logo from '../../assets/images/logo1-alt.png';
import { POST_API, setToken } from "../../services/server-api";
import { Navigate } from "react-router-dom";

class Screen extends React.Component {

    state = {
        _navigate: false
    }

    onSend = (values) => {

        POST_API('/credential/login.php', { token: 'X', master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setToken(res.token)
                this.setState({_navigate: true})
            }
        }).catch(() => {

        })

    }

    render = () => {

        const { Content } = Layout

        if (this.state._navigate) {
            return (<Navigate to="dashboard" />);
        }

        return (
            <Content>
                <Row style={{height: '100vh', alignItems: 'center'}} justify="center">
                    <Col className="sys-all-center sys-flex-column" style={{height: 300}} xs={20} md={6}>
                        <Image src={logo} preview={false} width={175} />
                        <Form layout="vertical" onFinish={this.onSend}>
                            <Form.Item name="LOGIN">
                                <TextField fullWidth variant="standard" label="E-mail" />
                            </Form.Item>
                            <Form.Item name="PASSWORD">
                                <TextField fullWidth type={'password'} variant="standard" label="Senha" />
                            </Form.Item>
                            <Form.Item>
                                <Button className="sys-btn-primary" htmlType="submit" block type="primary">Login</Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;