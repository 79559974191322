import React from "react";
import { Button, Card, Carousel, Col, Divider, Form, Image, Layout, message, Modal, Pagination, Progress, Row, Select, Spin, Typography } from 'antd';
import { Link, Navigate } from "react-router-dom";
import { ExclamationCircleOutlined } from '@ant-design/icons';

// API
import { getToken, POST_API } from "../../../services/server-api";

// TRANSLATE
import { getLanguageUpper, _p, _t } from "../../../services/language";

// CONTROLLER
import Page403 from '../../Controller/Page403';
import { MenuItem, TextField } from "@mui/material";
import { IoBarcode, IoBarcodeOutline } from "react-icons/io5";

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        _screen: false,
        _modal: false,
        _params: window.location.href.split('/'),
        defaultValue: {
            ID: 0
        },
        defaultValueCargo: {
            ID: 0,
        },
        values: {
            coords: {latitude: 'Indisponível', longitude: 'Indisponível'},
            last: '-'
        },
        avarias: [],
        _pagination: {
            current: 1,
            total: 0,
            limit: 5
        },
        search: ""
    }

    componentDidMount = () => {
        this.onView()
        this.loadOption()
        this.onRenderData()
    }

    GeoLocalSuccess = (value) => {
        this.setState({values: {...this.state.values, coords: value.coords}})
    }
    GeoLocalError = (value) => {
        this.setState({values: {...this.state.values, coords: {latitude: 'Indisponível', longitude: 'Indisponível'}}})
        message.error({ content: `Geolocalização indisponível no momento`, key: 'screen' })
    }

    onDelete = (ID) => {
        var self = this
        Modal.confirm({
            title: _t('Excluir registro?'),
            icon: <ExclamationCircleOutlined />,
            cancelText: _t('Não'),
            okText: _t('Sim'),
            className: 'sys-modal-confirm',
            onOk() {
                POST_API('/operation/cargo/breakdown/del.php', { token: getToken(), ID: ID }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: _p(res.msg), key: 'screen' })
                        self.onRenderData()
                    } else {
                        message.warning({ content: _p(res.msg), key: 'screen' })
                    }
                }).catch(() => {
                    message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
                }).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
    }

    loadOption = async () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.watchPosition(this.GeoLocalSuccess, this.GeoLocalError, {enableHighAccuracy: false, timeout: 3000, maximumAge: 0});
        } else {
            message.error({ content: _t('Geolocalização indisponível para o seu navegador.'), key: 'screen' })
            this.setState({values: {...this.state.values, coords: {latitude: 'Indisponível', longitude: 'Indisponível'}}})
        }
        await POST_API('/operation/cargo/search.php', { token: getToken(), filter: `{"STATUS":"S","OPERATION_ID":"${this.state._params[5]}","TYPE_READING_ID":"${this.state._params[8]}","TYPE_CARGO_ID":"${this.state._params[10]}"}`, sorter: `{"columnKey":"DATETIME_COLLECT","order":"descend"}` }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({ values: { ...this.state.values, LIDO: res.summary.QTDE }})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        }).finally(() => {
            
        })
        await POST_API('/operation/cargo/search.php', { token: getToken(), filter: `{"OPERATION_ID":"${this.state._params[5]}","TYPE_READING_ID":"${this.state._params[8]}","TYPE_CARGO_ID":"${this.state._params[10]}"}` }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({ values: { ...this.state.values, MIX: res.summary }})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
        await POST_API('/operation/cargo/search.php', { token: getToken(), filter: `{"OPERATION_ID":"${this.state._params[5]}","TYPE_READING_ID":"${this.state._params[8]}","TYPE_CARGO_ID":"${this.state._params[10]}"}` }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({ values: { ...this.state.values, MIX: res.summary.QTDE }})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
        await POST_API('/typereading/view.php', { token: getToken(), ID: this.state._params[8] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({values: {...this.state.values, TYPE_READING: res.data}})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
        await POST_API('/localreading/view.php', { token: getToken(), ID: this.state._params[7] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({values: {...this.state.values, LOCAL_READING: res.data}})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
        await POST_API('/subtypereading/view.php', { token: getToken(), ID: this.state._params[9] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({values: {...this.state.values, SUBTYPE_READING: res.data}})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
        await POST_API('/typecargo/view.php', { token: getToken(), ID: this.state._params[10] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({values: {...this.state.values, CARGO_TYPE: res.data}})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        }).finally(() => this.setState({_loading: false}))
    }

    onView = () => {
        var params = window.location.href.split('/')
        POST_API('/operation/view.php', { token: getToken(), ID: params[5] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                
                this.setState({_screen: true, defaultValue: res.data})
            } else {
                message.warning({ content: _p(res.msg), key: 'screen' })
                this.setState({_navigate: true})
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
            this.setState({_navigate: true})
        })
        POST_API('/operation/cargo/view.php', { token: getToken(), ID: params[11] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({_screen: true, defaultValueCargo: res.data})
            } else {
                message.warning({ content: _p(res.msg), key: 'screen' })
                this.setState({_navigate: true})
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
            this.setState({_navigate: true})
        })
    }

    onRenderData = async () => {
        await POST_API('/operation/cargo/breakdown/search.php', { token: getToken(), search: this.state.search, pagination: JSON.stringify(this.state._pagination), filter: `{"OPERATION_ID":"${this.state._params[5]}","OPERATION_CARGO_ID":"${this.state._params[11]}"}`, sorter: `{"columnKey":"operation_cargo_breakdown.DATETIME_INSERT","order":"descend"}` }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({ avarias: res.data, _pagination: { ...this.state._pagination, total: res.summary.QTDE }})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
    }

    onSend = async (values) => {
        this.setState({formvalues: values, _navigate: true})
    }

    onPaginationChange = async (page, size) => {
        await this.changePagination(page).then(() => {
            this.onRenderData()
        })
    }

    changePagination = async (page) => {
        this.setState({_pagination: {...this.state._pagination, current: page }})
    }

    changeSearch = async (e) => {
        this.setState({search: e.target.value})
    }

    onSearch = async (e) => {
        await this.changeSearch(e).then(() => this.onRenderData())
    }

    render = () => {

        const { Content } = Layout

        if (getToken() == null) {
            return (<Page403 />)
        }

        return (
            <Content className="sys-container">
                <Row gutter={[8,8]} justify="center">
                    <Col span={24}>
                        <Typography className="sys-title">- {_t('Realizar Inspeção')} -</Typography>
                    </Col>
                    {this.state._loading ? <Spin/> : (
                        <Col xs={24}>
                            <Row gutter={[8,8]}>
                                <Col span={24}>
                                    <Card size="small" hoverable className="sys-card-operation" title={`#${this.state.defaultValue.ID} ${this.state.defaultValue.SHIP_NAME} - ${this.state.defaultValue.PFD_NAME}`}>
                                        <Row gutter={[4,4]} style={{alignItems: 'center'}}>
                                            <Col span={12}><Typography><span className="sys-card-operation-title">{_t('Operação')}:</span> {this.state.values?.TYPE_READING[`NAME_${getLanguageUpper()}`]}</Typography></Col>
                                            <Col span={12}><Typography><span className="sys-card-operation-title">{_t('Leitura')}:</span> {this.state.values?.SUBTYPE_READING[`NAME_${getLanguageUpper()}`]}</Typography></Col>
                                            <Col span={12}><Typography><span className="sys-card-operation-title">{_t('Tipo de Carga')}:</span> {(this.state.values?.CARGO_TYPE[`NAME_${getLanguageUpper()}`]) ? this.state.values?.CARGO_TYPE[`NAME_${getLanguageUpper()}`] : ''}</Typography></Col>
                                            <Col span={12}><Typography><span className="sys-card-operation-title">{_t('Local')}:</span> {this.state.values?.LOCAL_READING[`NAME_${getLanguageUpper()}`]}</Typography></Col>
                                            <Col span={24}><Typography><span className="sys-card-operation-title">{_t('Qtde. Lida/Mix')}:</span> {this.state.values?.LIDO}/{this.state.values?.MIX} </Typography></Col>
                                            <Col span={12}><Typography><span className="sys-card-operation-title">Lat.:</span> {_t(this.state.values.coords.latitude)}</Typography></Col>
                                            <Col span={12}><Typography><span className="sys-card-operation-title">Long.:</span> {_t(this.state.values.coords.longitude)}</Typography></Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col span={24}>
                                    <Card size="small" style={{borderTopColor: this.state.defaultValueCargo?.POD_COLOR, borderTopWidth: 10, borderTopStyle: 'solid'}} hoverable className="sys-card-operation">
                                        <Row gutter={[4,0]} style={{alignItems: 'center'}}>
                                            <Col span={16}><Typography><span className="sys-card-operation-title">Vin Chassi:</span></Typography></Col>
                                            <Col span={8}><Typography><span className="sys-card-operation-title">{_t('Categoria')}:</span></Typography></Col>
                                            <Col span={16}><Typography><span>{this.state.defaultValueCargo?.CHASSI}</span></Typography></Col>
                                            <Col span={8}><Typography><span>{this.state.defaultValueCargo[`CATEGORY_NAME_`+getLanguageUpper()]}</span></Typography></Col>
                                            <Col span={24}><Typography><span className="sys-card-operation-title">POD:</span></Typography></Col>
                                            <Col span={24}><Typography><span>{this.state.defaultValueCargo?.POD_NAME}</span></Typography></Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col span={24}>
                                    <Row gutter={[16,16]}>
                                        {/* <Col span={8}><Button type="default" shape="round" block className="sys-btn-default">Voltar</Button></Col> */}
                                        <Col span={24}><Link to="add"><Button type="primary" shape="round" block className="sys-btn-primary">{_t('Nova Avaria')}</Button></Link></Col>
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <Divider >{_t('Avarias')}</Divider>
                                </Col>
                                <Col span={24}>
                                    <TextField value={this.state.search} onChange={this.onSearch} size="small" variant="standard" label={_t("Pesquisar...")} fullWidth />
                                </Col>
                                {this.state.avarias.length > 0 ? (
                                    <Col span={24}>
                                        <Row gutter={[8,8]}>
                                            {this.state._pagination?.current > 0 ? (
                                                <Col span={24}>
                                                    <Row justify="center">
                                                        <Pagination current={this.state._pagination.current} defaultCurrent={this.state._pagination.current} total={this.state._pagination.total} defaultPageSize={this.state._pagination.limit} onChange={this.onPaginationChange}/>
                                                    </Row>
                                                </Col>
                                            ) : null }
                                            {this.state.avarias.map((v,i) => (
                                                <Col span={24} key={i}>
                                                    <Card size="small" hoverable className="sys-card-operation">
                                                        <Row gutter={[4,4]} style={{alignItems: 'center'}}>
                                                            <Col span={24}><Typography><span className="sys-card-operation-title">{_t('Parte')}:</span> {v[`PART_NAME_${getLanguageUpper()}`]}</Typography></Col>
                                                            <Col span={24}><Typography><span className="sys-card-operation-title">{_t('Avaria')}:</span> {v[`BREAKDOWN_NAME_${getLanguageUpper()}`]}</Typography></Col>
                                                            <Col span={24}><Typography><span className="sys-card-operation-title">{_t('Severidade')}:</span> {v[`SEVERITY_NAME_${getLanguageUpper()}`]}</Typography></Col>
                                                            {v.PHOTOS.length > 0 ? (
                                                                <Col span={24}>
                                                                    <Carousel>
                                                                        {v.PHOTOS.map((v2,i2) => (
                                                                            <div>
                                                                                <Image src={v2.URL} width={'100%'} height={200} style={{objectFit: 'contain'}} />
                                                                            </div>
                                                                        ))}
                                                                    </Carousel>
                                                                </Col>
                                                            ) : null }
                                                                <Col span={24}>
                                                                <Row gutter={[16,16]}>
                                                                    <Col span={12}><Button onClick={() => this.onDelete(v.ID)} type="default" shape="round" block className="sys-btn-default">{_t('Excluir')}</Button></Col>
                                                                    <Col span={12}><Link to={`edit/${v.ID}`}><Button type="primary" shape="round" block className="sys-btn-primary">{_t('Atualizar')}</Button></Link></Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </Col>
                                            ))}
                                            {this.state._pagination?.current > 0 ? (
                                                <Col span={24}>
                                                    <Row justify="center">
                                                        <Pagination current={this.state._pagination.current} defaultCurrent={this.state._pagination.current} total={this.state._pagination.total} defaultPageSize={this.state._pagination.limit} onChange={this.onPaginationChange}/>
                                                    </Row>
                                                </Col>
                                            ) : null }
                                        </Row>
                                    </Col>
                                ) : (
                                    <Col span={24}>
                                        <Typography style={{textAlign: 'center'}}>- {_t('Nenhuma avaria encontrada')} -</Typography>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    )}
                </Row>
            </Content>
        )
    }

}

export default Screen;