import React from "react";
import { Breadcrumb, Button, Col, Form, Input, Layout, message, Row } from 'antd';
import { Link, Navigate } from "react-router-dom";
import { MenuItem, TextField } from "@mui/material";

// API
import { getToken, POST_API, verifyConfig } from "../../../services/server-api";

// TRANSLATE
import { getLanguageUpper, _p, _t } from "../../../services/language";

// CONTROLLER
import Page403 from '../../Controller/Page403';

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        _list_local_reading: [],
        _list_type_reading: [],
        defaultValue: {
            ID: 0
        }
    }

    componentDidMount = () => {
        this.loadOptions().then(() => this.setState({_loading: false}))
    }

    loadOptions = async () => {
        await POST_API('/typereading/filter.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({_list_type_reading: res.data})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
        await POST_API('/localreading/filter.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({_list_local_reading: res.data})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
    }

    onSend = (values) => {
        this.setState({_loading: true})
        POST_API('/subtypereading/save.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: _p(res.msg), key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        }).finally(() => this.setState({_loading: false}))
    }

    render = () => {

        const { Content } = Layout

        if (this.state._navigate) {
            return (<Navigate to={'/dashboard/subtypereading/'} />)
        }

        if (getToken() == null || !verifyConfig('OV8GM+RuCDMS0JcRBuRK9g==')) {
            return (<Page403 />)
        }

        return (
            <Content className="sys-container">
                <Row gutter={[8,8]}>
                    <Col span={20}>
                        <Breadcrumb className="sys-breadcrumb">
                            <Breadcrumb.Item><Link to="/dashboard/subtypereading">{_t('Subtipos de Leitura')}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>{_t('Adicionar')}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={4}>
                        <Link to="/dashboard/subtypereading"><Button type="primary" className="sys-btn-primary sys-btn-small float-right">{_t('Voltar')}</Button></Link>
                    </Col>
                    <Col span={24}>
                        <Form layout="vertical" className="sys-form" onFinish={this.onSend} initialValues={this.state.defaultValue}>
                            <Form.Item hidden name="ID">
                                <Input  />
                            </Form.Item>
                            <Row gutter={[8,0]}>
                                <Col xs={24} md={5}>
                                    <Form.Item name="TYPE_READING_ID" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                        <TextField fullWidth variant="standard" label={_t("Tipo de leitura")} select >
                                            { this.state._list_type_reading.map((v,i) => (
                                                <MenuItem value={v.ID} key={i}>{v['NAME_'+getLanguageUpper()]}</MenuItem>
                                            )) }
                                        </TextField>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={5}>
                                    <Form.Item name="LOCAL_READING_ID" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                        <TextField fullWidth variant="standard" label={_t("Local de leitura")} select >
                                            { this.state._list_local_reading.map((v,i) => (
                                                <MenuItem value={v.ID} key={i}>{v['NAME_'+getLanguageUpper()]}</MenuItem>
                                            )) }
                                        </TextField>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={7}>
                                    <Form.Item name="NAME_PT_BR" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                        <TextField fullWidth variant="standard" label={_t("Nome")+' (PT_BR)'} type='text' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={7}>
                                    <Form.Item name="NAME_EN_US" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                        <TextField fullWidth variant="standard" label={_t("Nome")+' (EN_US)'} type='text' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Button loading={this.state._loading} htmlType="submit" type="default" className="sys-btn-default float-right">{_t('Salvar')}</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;