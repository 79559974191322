import React from "react";
import { Breadcrumb, Button, Card, Checkbox, Col, Divider, Form, Input, Layout, message, Radio, Row, Space, Tooltip } from 'antd';
import { Link, Navigate } from "react-router-dom";
import { Chip, TextField } from "@mui/material";
import { IoInformationCircle } from "react-icons/io5";

// API
import { getToken, POST_API, verifyConfig } from "../../../../services/server-api";

// TRANSLATE
import { _p, _t } from "../../../../services/language";

// CONTROLLER
import Page403 from '../../../Controller/Page403';
import PageLoad from '../../../Controller/PageLoad';

//PERMISSIONS
import { _EDIT } from "./_permission";

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        _screen: false,
        _accountId: 0,
        _permission: {},
        _menu: [],
        _dash: [],
        defaultValue: {
            ID: 0,
        }
    }

    componentDidMount = () => {
        this.loadAccountId().finally(() =>this.onLoadPermission().then(() => this.onView()))
    }

    onLoadPermission = async () => {
        POST_API('/config/permissions.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({_permission: res.data, _menu: res.menu, _dash: res.dash})
            } else {
                message.warning({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        }).finally(() => this.setState({table__load: false}))
    }

    onView = () => {
        var params = window.location.href.split('/')
        message.loading({ content: _t('Carregando...'), key: 'screen' })
        POST_API('/account/typeuser/view.php', { token: getToken(), ID: params[7] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: _p(res.msg), key: 'screen' })
                this.setState({_screen: true, defaultValue: res.data})
            } else {
                message.warning({ content: _p(res.msg), key: 'screen' })
                this.setState({_navigate: true})
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
            this.setState({_navigate: true})
        }).finally(() => this.setState({_loading: false}))
    }

    loadAccountId = async () => {
        var params = window.location.href.split('/')
        message.loading({ content: _t('Carregando...'), key: 'screen' })
        POST_API('/account/view.php', { token: getToken(), ID: params[5] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({_accountId: params[5]})
            } else {
                message.warning({ content: _p(res.msg), key: 'screen' })
                this.setState({_navigate: true})
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
            this.setState({_navigate: true})
        }).finally(() => this.setState({_loading: false}))
    }

    onSend = (values) => {
        this.setState({_loading: true})
        values.ACCOUNT_ID = this.state._accountId
        POST_API('/account/typeuser/save.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: _p(res.msg), key: 'send' })
                this.onView()
            } else {
                message.warning({ content: _p(res.msg), key: 'send' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'send' })
        }).finally(() => this.setState({_loading: false}))
    }

    render = () => {

        const { Content } = Layout

        if (this.state._navigate) {
            return (<Navigate to={`/dashboard/account/${this.state._accountId}/typeuser`} />)
        }

        if (getToken() == null || !verifyConfig(_EDIT)) {
            return (<Page403 />)
        }

        return (
            <Content className="sys-container">
                <Row gutter={[8,8]}>
                    <Col span={20}>
                        <Breadcrumb className="sys-breadcrumb">
                            <Breadcrumb.Item><Link to="/dashboard/account">{_t('Empresas')}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>{this.state._accountId}</Breadcrumb.Item>
                            <Breadcrumb.Item><Link to={"/dashboard/account/"+this.state._accountId+"/typeuser"}>{_t('Tipos de Usuário')}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>{_t('Atualizar')}</Breadcrumb.Item>
                            <Breadcrumb.Item>{this.state.defaultValue.ID}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={4}>
                        <Link to={`/dashboard/account/${this.state._accountId}/typeuser`}><Button type="primary" className="sys-btn-primary sys-btn-small float-right">{_t('Voltar')}</Button></Link>
                    </Col>
                    <Col span={24}>
                    {this.state._screen && this.state._accountId ? (
                        <Form layout="vertical" className="sys-form" onFinish={this.onSend} initialValues={this.state.defaultValue} >
                            <Form.Item hidden name="ID">
                                <Input  />
                            </Form.Item>
                            <Row gutter={[8,0]}>
                                <Col xs={24} md={24}>
                                    <Form.Item name="NAME" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                        <TextField fullWidth variant="standard" label={_t("Nome")} type='text' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Divider><Chip label={_t('Permissões')}/></Divider>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Card className="sys-card" hoverable title={_t('Dashboard')}>
                                        <Form.Item name="CONFIGS_EXT" style={{marginBottom: '-7px'}}>
                                            <Radio.Group>
                                                <Space direction="vertical">
                                                    {this.state._dash.map((item, index) =>(
                                                        <Radio key={index} value={item.ID}>{item.DESC}</Radio>
                                                    ))}
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Card>
                                    <Form.Item name="CONFIGS">
                                        <Checkbox.Group style={{ width: '100%' }} >
                                            <Row gutter={[8,8]}>
                                                <Col xs={24} md={24}>
                                                    <Card className="sys-card" hoverable title={_t('Menu')}>
                                                        <Row>
                                                            {this.state._menu.map((item, index) =>(
                                                                <Col xs={24} md={8} key={index} style={{ display: 'flex', alignItems: 'center'}}>
                                                                    <Tooltip placement="right" title={item.DESC}><IoInformationCircle size={18} style={{marginRight: 5}} /></Tooltip><Checkbox value={item.ID} >{item.KEY}</Checkbox>
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    </Card>
                                                </Col>
                                                {Object.keys(this.state._permission).map((v, i) =>(
                                                    <Col xs={24} md={8} key={i}>
                                                        <Card className="sys-card" hoverable title={_t(v)}>
                                                            <Row>
                                                            {this.state._permission[v].map((item, index) =>(
                                                                <Col span={24} key={index} style={{ display: 'flex', alignItems: 'center'}}>
                                                                    <Tooltip placement="right" title={item.DESC}><IoInformationCircle size={18} style={{marginRight: 5}} /></Tooltip><Checkbox value={item.ID} >{item.KEY}</Checkbox>
                                                                </Col>
                                                            ))}
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Checkbox.Group>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Button loading={this.state._loading} htmlType="submit" type="default" className="sys-btn-default float-right">{_t('Salvar')}</Button>
                                </Col>
                            </Row>
                        </Form>
                    ) : <PageLoad /> }
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;