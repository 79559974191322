import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { POST_API, setFooter, setLogoLogin, setLogoNavbar, setLogoSidebar, ENV_ID, setColor } from './services/server-api';
import Page500 from './pages/Controller/Page500';
import PageLoad from './pages/Controller/PageLoad';

const root = ReactDOM.createRoot(document.getElementById('root'));
const favicon = document.getElementById("favicon");

root.render(
    <PageLoad />
);

POST_API('/enviroment/load.php', { ENV_ID }).then(rs => rs.json()).then(res => {
  if (res.return) {
    document.querySelector(':root').style.setProperty('--primary', `${res.env.COLOR_PRIMARY}`);
    document.querySelector(':root').style.setProperty('--secondary', `${res.env.COLOR_SECONDARY}`);
    document.title = res.env.NAME
    favicon.setAttribute("href", res.env.LOGO_LINK); 
    setColor(res.env.COLOR_PRIMARY)
    setLogoLogin(res.env.LOGO_LOGIN)
    setLogoNavbar(res.env.LOGO_NAVBAR)
    setLogoSidebar(res.env.LOGO_SIDEBAR)
    setFooter(res.env.FOOTER)
  }
  return res.return
}).then((a) => {
  if (a) {
    root.render(
        <App />
    );
  } else {
    root.render(
        <Page500 />
    );
  }
}).catch(() => {
  root.render(
      <Page500 />
  );
})
