import React from "react";
import { Button, Card, Col, Divider, Form, Layout, message, Modal, Pagination, Progress, Row, Select, Spin, Typography } from 'antd';
import { Link, Navigate } from "react-router-dom";

// API
import { getToken, POST_API } from "../../../services/server-api";

// TRANSLATE
import { getLanguageUpper, _p, _t } from "../../../services/language";

// CONTROLLER
import Page403 from '../../Controller/Page403';
import { MenuItem, TextField } from "@mui/material";
import Beep from "../../../assets/sounds/beep.mp3";

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        _screen: false,
        _modal: false,
        _params: window.location.href.split('/'),
        defaultValue: {
            ID: 0
        },
        values: {
            coords: {latitude: 'Indisponível', longitude: 'Indisponível'},
            last: '-'
        },
        chassis: [],
        _pagination: {
            current: 1,
            total: 0,
            limit: 10
        },
        search: ""
    }

    componentDidMount = () => {
        this.onView()
        this.loadOption().then((() => this.setState({_loading: false})))
        this.onRenderData()
    }

    GeoLocalSuccess = (value) => {
        this.setState({values: {...this.state.values, coords: value.coords}})
    }
    GeoLocalError = (value) => {
        this.setState({values: {...this.state.values, coords: {latitude: 'Indisponível', longitude: 'Indisponível'}}})
        message.error({ content: `Geolocalização indisponível no momento`, key: 'screen' })
    }

    loadOption = async () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.watchPosition(this.GeoLocalSuccess, this.GeoLocalError, {enableHighAccuracy: false, timeout: 3000, maximumAge: 0});
        } else {
            message.error({ content: _t('Geolocalização indisponível para o seu navegador.'), key: 'screen' })
            this.setState({values: {...this.state.values, coords: {latitude: 'Indisponível', longitude: 'Indisponível'}}})
        }
        await POST_API('/operation/cargo/search.php', { token: getToken(), filter: `{"STATUS":"S","OPERATION_ID":"${this.state._params[5]}","TYPE_READING_ID":"${this.state._params[8]}","TYPE_CARGO_ID":"${this.state._params[10]}"}`, sorter: `{"columnKey":"DATETIME_COLLECT","order":"ascend"}` }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({ values: { ...this.state.values, LIDO: res.summary.QTDE }})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        }).finally(() => {
            
        })
        await POST_API('/operation/cargo/search.php', { token: getToken(), filter: `{"OPERATION_ID":"${this.state._params[5]}","TYPE_READING_ID":"${this.state._params[8]}","TYPE_CARGO_ID":"${this.state._params[10]}"}` }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({ values: { ...this.state.values, MIX: res.summary }})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
        await POST_API('/operation/cargo/search.php', { token: getToken(), filter: `{"OPERATION_ID":"${this.state._params[5]}","TYPE_READING_ID":"${this.state._params[8]}","TYPE_CARGO_ID":"${this.state._params[10]}"}` }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({ values: { ...this.state.values, MIX: res.summary.QTDE }})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
        await POST_API('/typereading/view.php', { token: getToken(), ID: this.state._params[8] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({values: {...this.state.values, TYPE_READING: res.data}})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
        await POST_API('/localreading/view.php', { token: getToken(), ID: this.state._params[7] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({values: {...this.state.values, LOCAL_READING: res.data}})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
        await POST_API('/subtypereading/view.php', { token: getToken(), ID: this.state._params[9] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({values: {...this.state.values, SUBTYPE_READING: res.data}})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
        await POST_API('/typecargo/view.php', { token: getToken(), ID: this.state._params[10] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({values: {...this.state.values, CARGO_TYPE: res.data}})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
    }

    onView = () => {
        var params = window.location.href.split('/')
        POST_API('/operation/view.php', { token: getToken(), ID: params[5] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                
                this.setState({_screen: true, defaultValue: res.data})
            } else {
                message.warning({ content: _p(res.msg), key: 'screen' })
                this.setState({_navigate: true})
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
            this.setState({_navigate: true})
        })
    }

    onRenderData = async () => {
        await POST_API('/operation/cargo/search.php', { token: getToken(), search: this.state.search, pagination: JSON.stringify(this.state._pagination), filter: `{"OPERATION_ID":"${this.state._params[5]}","TYPE_READING_ID":"${this.state._params[8]}","TYPE_CARGO_ID":"${this.state._params[10]}"}`, sorter: `{"columnKey":"DATETIME_COLLECT","order":"ascend"}` }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({ chassis: res.data, _pagination: { ...this.state._pagination, total: res.summary.QTDE }})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        }).finally(() => {
        })
    }

    onSend = async (values) => {
        this.setState({formvalues: values, _navigate: true})
    }

    onPaginationChange = async (page, size) => {
        await this.changePagination(page).then(() => {
            this.onRenderData()
        })
    }

    changePagination = async (page) => {
        this.setState({_pagination: {...this.state._pagination, current: page }})
    }

    changeSearch = async (e) => {
        this.setState({search: e.target.value})
    }

    onSearch = async (e) => {
        await this.changeSearch(e).then(() => this.onRenderData())
    }

    onReader = async (v) => {
        var dados = {
            OPERATION_ID: this.state._params[5],
            LOCAL_READING_ID: this.state._params[7],
            TYPE_READING_ID: this.state._params[8],
            SUBTYPE_READING_ID: this.state._params[9],
            TYPE_CARGO_ID: this.state._params[10],
            CHASSI: v.CHASSI,
            LATITUDE: this.state.values.coords.latitude,
            LONGITUDE: this.state.values.coords.longitude,
        }
        POST_API('/operation/cargo/collect.php', { token: getToken(), ...dados }).then(rs => rs.json()).then(res => {
            if (res.return) {
                var audio = new Audio(Beep)
                audio.play();
                message.success({ content: _p(res.msg), key: 'screen' })
                this.setState({reader: res.data.ID})
                setTimeout(() => {
                    this.setState({_navigate: true})
                }, 1000)
            } else {
                // message.warning({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        }).finally(() => {})
    }

    render = () => {

        const { Content } = Layout

        if (getToken() == null) {
            return (<Page403 />)
        }

        if (this.state._navigate) {
            return (<Navigate to={`/dashboard/inspector/${this.state._params[5]}/collect/${this.state._params[7]}/${this.state._params[8]}/${this.state._params[9]}/${this.state._params[10]}/${this.state.reader}`} />)
        }

        return (
            <Content className="sys-container">
                <Row gutter={[8,8]} justify="center">
                    <Col span={24}>
                        <Typography className="sys-title">- {_t('Procurar Chassi')} -</Typography>
                    </Col>
                    {this.state._loading ? <Spin/> : (
                        <Col xs={24}>
                            <Row gutter={[8,8]}>
                                <Col span={24}>
                                    <Card size="small" hoverable className="sys-card-operation" title={`#${this.state.defaultValue.ID} ${this.state.defaultValue.SHIP_NAME} - ${this.state.defaultValue.PFD_NAME}`}>
                                        <Row gutter={[4,4]} style={{alignItems: 'center'}}>
                                            <Col span={12}><Typography><span className="sys-card-operation-title">{_t('Operação')}:</span> {this.state.values?.TYPE_READING[`NAME_${getLanguageUpper()}`]}</Typography></Col>
                                            <Col span={12}><Typography><span className="sys-card-operation-title">{_t('Leitura')}:</span> {this.state.values?.SUBTYPE_READING[`NAME_${getLanguageUpper()}`]}</Typography></Col>
                                            <Col span={12}><Typography><span className="sys-card-operation-title">{_t('Tipo de Carga')}:</span> {(this.state.values?.CARGO_TYPE[`NAME_${getLanguageUpper()}`]) ? this.state.values?.CARGO_TYPE[`NAME_${getLanguageUpper()}`] : ''}</Typography></Col>
                                            <Col span={12}><Typography><span className="sys-card-operation-title">{_t('Local')}:</span> {this.state.values?.LOCAL_READING[`NAME_${getLanguageUpper()}`]}</Typography></Col>
                                            <Col span={24}><Typography><span className="sys-card-operation-title">{_t('Qtde. Lida/Mix')}:</span> {this.state.values?.LIDO}/{this.state.values?.MIX} </Typography></Col>
                                            <Col span={12}><Typography><span className="sys-card-operation-title">Lat.:</span> {_t(this.state.values.coords.latitude)}</Typography></Col>
                                            <Col span={12}><Typography><span className="sys-card-operation-title">Long.:</span> {_t(this.state.values.coords.longitude)}</Typography></Col>
                                            <Col span={24}><Typography><span className="sys-card-operation-title">{_t('Última Leitura')}:</span> {this.state.values.last}</Typography></Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col span={24}>
                                    <TextField value={this.state.search} onChange={this.onSearch} size="small" variant="standard" label={_t("Pesquisar...")} fullWidth />
                                </Col>
                                {this.state.chassis.length > 0 ? (
                                    <Col span={24}>
                                        <Row gutter={[8,8]}>
                                            {this.state._pagination?.current > 0 ? (
                                                <Col span={24}>
                                                    <Row justify="center">
                                                        <Pagination current={this.state._pagination.current} defaultCurrent={this.state._pagination.current} total={this.state._pagination.total} defaultPageSize={this.state._pagination.limit} onChange={this.onPaginationChange}/>
                                                    </Row>
                                                </Col>
                                            ) : null }
                                            {this.state.chassis.map((v,i) => (
                                                <Col span={24} key={i}>
                                                    <Card onClick={() => this.onReader(v)} size="small" style={{borderTopColor: v.POD_COLOR, borderTopWidth: 10, borderTopStyle: 'solid'}} hoverable className="sys-card-operation">
                                                        <Row gutter={[4,0]} style={{alignItems: 'center'}}>
                                                            <Col span={16}><Typography><span className="sys-card-operation-title">Vin Chassi:</span></Typography></Col>
                                                            <Col span={8}><Typography><span className="sys-card-operation-title">{_t('Categoria')}:</span></Typography></Col>
                                                            <Col span={16}><Typography><span>{v.CHASSI}</span></Typography></Col>
                                                            <Col span={8}><Typography><span>{v[`CATEGORY_NAME_`+getLanguageUpper()]}</span></Typography></Col>
                                                            <Col span={24}><Typography><span className="sys-card-operation-title">POD:</span></Typography></Col>
                                                            <Col span={24}><Typography><span>{v.POD_NAME}</span></Typography></Col>
                                                        </Row>
                                                    </Card>
                                                </Col>
                                            ))}
                                            {this.state._pagination?.current > 0 ? (
                                                <Col span={24}>
                                                    <Row justify="center">
                                                        <Pagination current={this.state._pagination.current} defaultCurrent={this.state._pagination.current} total={this.state._pagination.total} defaultPageSize={this.state._pagination.limit} onChange={this.onPaginationChange}/>
                                                    </Row>
                                                </Col>
                                            ) : null }
                                        </Row>
                                    </Col>
                                ) : (
                                    <Col span={24}>
                                        <Typography style={{textAlign: 'center'}}>- {_t('Nenhum chassi coletado')} -</Typography>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    )}
                </Row>
            </Content>
        )
    }

}

export default Screen;