import React from "react";
import HomeAdmin from "./HomeAdmin";
import HomeInspection from "./HomeInspection";
import HomeClient from "./HomeClient";

// SERVICES
import { verifyConfig } from "../../../services/server-api";

class Screen extends React.Component {

    render = () => {

        if (verifyConfig('EVaUY1MfNVIAhdncgbD/C2AdKkosA1iGsyHfP7GyJaQ=')) return ( <></> );

        if (verifyConfig('a99odWPFRuy8oDT9XYSpjC5mFQw2QmEqSEzFpTPD8gs=')) return ( <HomeAdmin /> );
        if (verifyConfig('a99odWPFRuy8oDT9XYSpjAimwE/DN2oci7TqpPMRV9Y=')) return ( <HomeInspection /> );

        return ( <HomeClient /> )

    }        

}

export default Screen;