import React from "react";
import { Button, Col, Collapse, Layout, Row, message } from 'antd';
import { Link } from "react-router-dom";

import { IoCubeOutline, IoBusinessOutline, IoFileTrayStackedOutline, IoFolderOpenOutline, IoPeopleOutline, IoLogoElectron, IoLockClosedOutline, IoPinOutline, IoPricetagsOutline, IoPulseOutline, IoSparklesOutline, IoBoatOutline, IoClipboardOutline, IoStorefrontOutline, IoStarOutline, IoEaselOutline, IoCopyOutline, IoChatboxOutline, IoSettingsOutline, IoBookOutline, IoFolderOutline } from 'react-icons/io5';
import { POST_API, getToken, verifyConfig } from "../../services/server-api";
import { _p, _t } from "../../services/language";

class Screen extends React.Component {

    state = { select: 'dashboard', paths: [] }

    componentDidMount = () => {
        
        const params = window.location.href;
        const values = params.split('/');

        values[4] = values[4] ? values[4] : values[3]
        values[4] = values[4] == 'viewpath' ? values[4]+'/'+values[5] : values[4]
        
        this.onSelect(values[4])

        this.onLoadPath()

    }

    onSelect = (page) => {
        this.setState({select: page})
    }

    onLoadPath = async () => {
        POST_API('/path/filter.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({ paths: res.data })
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        }).finally(() => this.setState({load: false})); 
    }

    render = () => {

        const { Content } = Layout
        const menus = [
            { label: _t('Suporte'), url: 'support', value: 'support', icon: <IoChatboxOutline size={22}/>, permission: 'iiidg/D6SYgXh82wHm49oA==' },
            { label: _t('Ambientes'), url: 'enviroment', value: 'enviroment', icon: <IoFolderOpenOutline size={22}/>, permission: '7gXkZauIRFQ12cO1jawuYw==' },
            { label: _t('Empresas'), url: 'account', value: 'account', icon: <IoBusinessOutline size={22}/>, permission: 'UuKK5M2KEpo+MTTib5WmOQ==' },
            { label: _t('Pastas'), url: 'path', value: 'path', icon: <IoFolderOutline size={22}/>, permission: 'x37Di8CI+EnU7CDW1ps5qA==' },
            { label: _t('Operações'), url: 'operation', value: 'operation', icon: <IoClipboardOutline size={22}/>, permission: 'czQUl7gpXrq0IhbXGPf7YA==' },
            { label: _t('Etapas'), url: 'step', value: 'step', icon: <IoFileTrayStackedOutline size={22}/>, permission: 'czQUl7gpXrq0IhbXGPf7YA==' },
            { label: _t('Situações'), url: 'status', value: 'status', icon: <IoSparklesOutline size={22}/>, permission: 'MDgzxR1cR8g71EP6dpNIAA==' },
            { label: _t('Armadores'), url: 'shipowner', value: 'shipowner', icon: <IoStarOutline size={22}/>, permission: 'AFXmRmr4zaETFPMFqurRFA==' },
            { label: _t('Embarcações'), url: 'ship', value: 'ship', icon: <IoBoatOutline size={22}/>, permission: 'H115qqxy7JckaCplGk9GOg==' },
            { label: _t('Portos'), url: 'port', value: 'port', icon: <IoPinOutline size={22}/>, permission: '3Z3dYb3QvAiYXE30Wavgzg==' },
            { label: _t('Companhias'), url: 'company', value: 'company', icon: <IoStorefrontOutline size={22}/>, permission: 'G1rh51n6J0M37Mu9bU2aBA==' },
            { label: _t('Tipos de Carga'), url: 'typecargo', value: 'typecargo', icon: <IoPricetagsOutline size={22}/>, permission: 'FRUCcWrabe4H186a6+KkaQ==' },
            { label: _t('Severidades'), url: 'severity', value: 'severity', icon: <IoPulseOutline size={22}/>, permission: 'rEgYimF7gWEcGiTxAZJ+Tw==' },
            { label: _t('Local de Leitura'), url: 'localreading', value: 'localreading', icon: <IoEaselOutline size={22}/>, permission: '1WLpH0+KPzpG+S707W774bF9hPk1va070QFVqxpvWM8=' },
            { label: _t('Subtipo de Leitura'), url: 'subtypereading', value: 'subtypereading', icon: <IoCopyOutline size={22}/>, permission: 'rEgYimF7gWEcGiTxAZJ+Tw==' },
            { label: _t('Usuários'), url: 'user', value: 'user', icon: <IoPeopleOutline size={22}/>, permission: 'TxXNaOL5smWqq1ch35PWZA==' },
            { label: _t('Tipos de Usuário'), url: 'typeuser', value: 'typeuser', icon: <IoLogoElectron size={22}/>, permission: 'uKwnoIJ8GVI94LYoFrF7kA==' },
            { label: _t('Permissões'), url: 'permission', value: 'permission', icon: <IoLockClosedOutline size={22}/>, permission: 'ojd3mVYDU8pmCZVEiUvUfQ==' },
            { label: _t('Configurações'), url: 'settings', value: 'settings', icon: <IoSettingsOutline size={22}/>, permission: '02EfRI4RaH+gYhhsxe/gfw==' },
            { label: _t('Histórico'), url: 'log', value: 'log', icon: <IoBookOutline size={22}/>, permission: 'IjXYFBF0h2hlFVvm9qjBEg==' },
        ]

        return (
            <Content style={{marginTop: 15}}>
                <Row>
                    <Col span={24} className="sys_sidebar__col_item" style={{borderBottom: '1px solid #00000028', paddingBottom: 8}}>
                        <Link to={'/dashboard'}>
                            <Button onClick={() => this.onSelect('dashboard')} type="link" block className={this.state.select === 'dashboard' ? "sys_sidebar__col_button active" : "sys_sidebar__col_button"}>
                                <IoCubeOutline size={22}/> {_t('Dashboard')} 
                            </Button>
                        </Link>
                    </Col>
                    <Col span={24}>
                        <Collapse>
                            <Collapse.Panel header={_t('Pastas')} className="sys-collapse">
                                <Row gutter={[4,4]} style={{marginTop: 10, marginBottom: 10}}>
                                    {this.state.paths.map((v,i) => (
                                        <Col key={i} span={24} className="sys_sidebar__col_item">
                                            <Link to={`viewpath/${v.ID}`}>
                                                <Button onClick={() => this.onSelect(`viewpath/${v.ID}`)} type="link" block className={this.state.select === `viewpath/${v.ID}` ? "sys_sidebar__col_button active" : "sys_sidebar__col_button"}>
                                                    <IoFolderOpenOutline size={22}/> {v.NAME} {`(${v.QTD})`}
                                                </Button>
                                            </Link>
                                        </Col>
                                    ))} 
                                </Row>
                            </Collapse.Panel>
                        </Collapse>
                    </Col>
                    { verifyConfig('a99odWPFRuy8oDT9XYSpjC5mFQw2QmEqSEzFpTPD8gs=') || verifyConfig('a99odWPFRuy8oDT9XYSpjAimwE/DN2oci7TqpPMRV9Y=') || verifyConfig('EVaUY1MfNVIAhdncgbD/C2AdKkosA1iGsyHfP7GyJaQ=') ? (
                    <Col span={24}>
                        <Collapse>
                            <Collapse.Panel header={_t("Sistema")} className="sys-collapse">
                                <Row gutter={[4,4]} style={{marginTop: 10, marginBottom: 10}}> 
                                    {menus.map((v, i) => {

                                        if (v.permission === null || verifyConfig(v.permission)) {
                                            return (
                                                <Col span={24} className="sys_sidebar__col_item" key={i}>
                                                    <Link to={v.url}>
                                                        <Button onClick={() => this.onSelect(v.value)} type="link" block className={this.state.select === v.value ? "sys_sidebar__col_button active" : "sys_sidebar__col_button"}>
                                                            {v.icon} {v.label} 
                                                        </Button>
                                                    </Link>
                                                </Col>
                                            )
                                        }
                                        return null;
                                        
                                    })}
                                </Row>
                            </Collapse.Panel>
                        </Collapse>
                    </Col>
                    ) : null }
                                    
                </Row>
            </Content>
        )
    }

}

export default Screen;