import React from "react";
import { Button, Card, Col, Divider, Form, Image, Input, Layout, message, Modal, Pagination, Progress, Row, Select, Spin, Typography, Upload } from 'antd';
import { Link, Navigate } from "react-router-dom";
import { ExclamationCircleOutlined } from '@ant-design/icons';

// API
import { getToken, POST_API, UPLOAD_API } from "../../../services/server-api";

// TRANSLATE
import { getLanguageUpper, _p, _t } from "../../../services/language";

// CONTROLLER
import Page403 from '../../Controller/Page403';
import { Chip, MenuItem, TextField } from "@mui/material";
import { IoClose, IoPencil, IoTrash, IoTrashBin } from "react-icons/io5";

class Screen extends React.Component {

    state = {
        _loading_button: false,
        _navigate: false,
        _loading: true,
        _screen: false,
        _data: false,
        _modal: false,
        _params: window.location.href.split('/'),
        defaultValue: {
            ID: 0
        },
        defaultValueDatehour: { ID: 0 },
        photos: []
    }

    componentDidMount = () => {
        this.onView()
        this.onRenderData()
    }

    onView = () => {
        var params = window.location.href.split('/')
        POST_API('/operation/view.php', { token: getToken(), ID: params[5] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({_screen: true, defaultValue: res.data})
            } else {
                message.warning({ content: _p(res.msg), key: 'screen' })
                this.setState({_navigate: true})
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
            this.setState({_navigate: true})
        })
    }

    onRenderData = async () => {
        await POST_API('/operation/detail/search.php', { token: getToken(), filter: `{"OPERATION_ID":"${this.state._params[5]}","STEP_ID":"${this.state._params[7]}"}` }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({ photos: res.data })
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        }).finally(() => { })
    }

    onSend = async (values) => {
        this.setState({_loading_button: true, _data: true})

        values.OPERATION_ID = this.state._params[5];

        POST_API('/operation/detail/save.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: _p(res.msg), key: 'screen' })
                this.onRenderData()
                this.setState({_navigate: true, defaultValueDatehour: { ID: 0 }})
            } else {
                message.warning({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        }).finally(() => this.setState({_loading_button: false, _data: false}))
    }

    onPaginationChange = async (page, size) => {
        await this.changePagination(page).then(() => {
            this.onRenderData()
        })
    }

    changePagination = async (page) => {
        this.setState({_pagination: {...this.state._pagination, current: page }})
    }

    changeSearch = async (e) => {
        this.setState({search: e.target.value})
    }

    onSearch = async (e) => {
        await this.changeSearch(e).then(() => this.onRenderData())
    }

    onDelete = (ID) => {
        var self = this
        Modal.confirm({
            title: _t('Excluir registro?'),
            icon: <ExclamationCircleOutlined />,
            cancelText: _t('Não'),
            okText: _t('Sim'),
            className: 'sys-modal-confirm',
            onOk() {
                POST_API('/operation/detail/del.php', { token: getToken(), ID: ID }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: _p(res.msg), key: 'screen' })
                        self.onRenderData()
                    } else {
                        message.warning({ content: _p(res.msg), key: 'screen' })
                    }
                }).catch(() => {
                    message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
                }).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
    }

    onEdit = (ID) => {
        this.setState({_data: true})
        POST_API('/operation/detail/view.php', { token: getToken(), ID: ID }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({defaultValueDatehour: res.data})
            } else {
                message.warning({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        }).finally(() => {
            this.setState({_data: false})
        })
    }

    render = () => {

        const { Content } = Layout

        if (getToken() == null) {
            return (<Page403 />)
        }

        return (
            <Content className="sys-container">
                <Row gutter={[8,8]} justify="center">
                    {!this.state._screen ? <Spin/> : (
                        <>
                            <Col span={24}>
                                <Typography className="sys-title">- {_t('Detalhes da Operação')} -</Typography>
                            </Col>
                            {this.state._data ? <Spin/> : (
                                <Col xs={24}>
                                    <Form onFinish={this.onSend} layout="vertical" initialValues={this.state.defaultValueDatehour}>
                                        <Form.Item name="ID" hidden>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name="DETAIL_EN_US" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                            <TextField fullWidth variant="standard" label={_t("Detalhe")+' (EN_US)'} type='text' />
                                        </Form.Item>
                                        <Form.Item name="DETAIL_PT_BR" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                            <TextField fullWidth variant="standard" label={_t("Detalhe")+' (PT_BR)'} type='text' />
                                        </Form.Item>
                                        <Form.Item name="ORDER" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                            <TextField fullWidth variant="standard" label={_t("Ordem")} type='text' />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button loading={this.state._loading_button} htmlType="submit" type="default" className="sys-btn-default" block>{_t('Salvar')}</Button>
                                        </Form.Item>
                                    </Form>
                                </Col>
                            )}
                            <Col xs={24} md={24}>
                                <Divider><Chip label={_t('Lista')}/></Divider>
                            </Col>
                            {this.state.photos.length > 0 ? (
                                <Col span={24}>
                                    <Row gutter={[8,8]}>
                                        {this.state.photos.map((v, i) => (
                                            <Col key={i} span={24}>
                                                <Card actions={[
                                                    <Button onClick={() => this.onEdit(v.ID)} shape="circle" className="table__btn_edit"><IoPencil color="#FFFF" size={22} /></Button>,
                                                    <Button onClick={() => this.onDelete(v.ID)} shape="circle" className="sys-btn-danger"><IoTrashBin color="#FFFF" size={22} /></Button>,
                                                ]}>
                                                    <Typography><b>{_t('Ordem')}:</b> {v[`ORDER`]}</Typography>
                                                    <Typography><b>{_t('Detalhe')}:</b> {v[`DETAIL_${getLanguageUpper()}`]}</Typography>
                                                </Card>
                                                
                                                <Image style={{borderRadius: 8}} src={v.URL} />
                                            </Col>
                                        ))}
                                    </Row>
                                </Col>
                            ) : (
                                <Col span={24}>
                                    <Typography style={{textAlign: 'center'}}>- {_t('Nenhum item encontrado')} -</Typography>
                                </Col>
                            )}
                        </>
                    )}
                </Row>
            </Content>
        )
    }

}

export default Screen;