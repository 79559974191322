const values = {
    "Meu perfil" : {
        "pt_br": "Meu perfil",
        "en_us": "My profile",
    },
    "Notificações" : {
        "pt_br": "Notificações",
        "en_us": "Notifications",
    },
    "Sair" : {
        "pt_br": "Sair",
        "en_us": "Exit",
    },
    "Sim" : {
        "pt_br": "Sim",
        "en_us": "Yes",
    },
    "Não" : {
        "pt_br": "Não",
        "en_us": "No",
    },
    "Sistema" : {
        "pt_br": "Sistema",
        "en_us": "System",
    },
    "Detalhes da Operação" : {
        "pt_br": "Detalhes da Operação",
        "en_us": "Details Operation",
    },
    "Detalhes de Data/Hora" : {
        "pt_br": "Detalhes de Data/Hora",
        "en_us": "Details Date/Hour",
    },
    "Data/Hora" : {
        "pt_br": "Data/Hora",
        "en_us": "Date/Hour",
    },
    "Ordem" : {
        "pt_br": "Ordem",
        "en_us": "Order",
    },
    "Pastas" : {
        "pt_br": "Pastas",
        "en_us": "Folders",
    },
    "Sair do sistema?" : {
        "pt_br": "Sair do sistema?",
        "en_us": "Log out of the system?",
    },
    "E-mail" : {
        "pt_br": "E-mail",
        "en_us": "Email",
    },
    "Senha" : {
        "pt_br": "Senha",
        "en_us": "Password",
    },
    "Não foi possível estabelecer uma conexão com o sistema" : {
        "pt_br": "Não foi possível estabelecer uma conexão com o sistema",
        "en_us": "Unable to establish a connection to the system",
    },
    "Carregando..." : {
        "pt_br": "Carregando...",
        "en_us": "Loading...",
    },
    "Novo Usuário" : {
        "pt_br": "Novo Usuário",
        "en_us": "New User",
    },
    "Redefinir Senha" : {
        "pt_br": "Redefinir Senha",
        "en_us": "Redefine password",
    },
    "Redefinir a senha para usuário?" : {
        "pt_br": "Redefinir a senha para usuário?",
        "en_us": "Reset password for user?",
    },
    "Empresas" : {
        "pt_br": "Empresas",
        "en_us": "Accounts",
    },
    "Desculpe, página não encontrada." : {
        "pt_br": "Desculpe, página não encontrada.",
        "en_us": "Sorry, the page you visited does not exist.",
    },
    "Desculpe, você não tem autorização para acessar essa página." : {
        "pt_br": "Desculpe, você não tem autorização para acessar essa página.",
        "en_us": "Sorry, you are not authorized to access this page.",
    },
    "Erro no sistema! Por favor, entre em contato com o suporte." : {
        "pt_br": "Erro no sistema! Por favor, entre em contato com o suporte.",
        "en_us": "System error! Please contact support.",
    },
    "Voltar para o site" : {
        "pt_br": "Voltar para o site",
        "en_us": "Back Home",
    },
    "Suporte" : {
        "pt_br": "Suporte",
        "en_us": "Support",
    },
    "Ambientes" : {
        "pt_br": "Ambientes",
        "en_us": "Enviroments",
    },
    "Usuários" : {
        "pt_br": "Usuários",
        "en_us": "Users",
    },
    "Tipos de Usuário" : {
        "pt_br": "Tipos de Usuário",
        "en_us": "Types of User",
    },
    "Tipo de Usuário" : {
        "pt_br": "Tipo de Usuário",
        "en_us": "Type of User",
    },
    "Permissões" : {
        "pt_br": "Permissões",
        "en_us": "Permissions",
    },
    "Lista" : {
        "pt_br": "Lista",
        "en_us": "List",
    },
    "Histórico" : {
        "pt_br": "Histórico",
        "en_us": "Logs",
    },
    "Configurações" : {
        "pt_br": "Configurações",
        "en_us": "Settings",
    },
    "Novo Ambiente" : {
        "pt_br": "Novo Ambiente",
        "en_us": "New Environment",
    },
    "Total de" : {
        "pt_br": "Total de",
        "en_us": "Total of",
    },
    "registros retornados" : {
        "pt_br": "registros retornados",
        "en_us": "records returned",
    },
    "Pesquisar..." : {
        "pt_br": "Pesquisar...",
        "en_us": "Search...",
    },
    "Nome" : {
        "pt_br": "Nome",
        "en_us": "Name",
    },
    "Ações" : {
        "pt_br": "Ações",
        "en_us": "Actions",
    },
    "Adicionar" : {
        "pt_br": "Adicionar",
        "en_us": "Add",
    },
    "Voltar" : {
        "pt_br": "Voltar",
        "en_us": "Back",
    },
    "Cor Primária" : {
        "pt_br": "Cor Primária",
        "en_us": "Primary Color",
    },
    "Cor Secundária" : {
        "pt_br": "Cor Secundária",
        "en_us": "Secondary Color",
    },
    "Rodapé" : {
        "pt_br": "Rodapé",
        "en_us": "Footer",
    },
    "Selecionar Favicon" : {
        "pt_br": "Selecionar Favicon",
        "en_us": "Select Favicon",
    },
    "Selecionar Imagem Login" : {
        "pt_br": "Selecionar Imagem Login",
        "en_us": "Select Image Login",
    },
    "Selecionar Imagem Cabeçalho" : {
        "pt_br": "Selecionar Imagem Cabeçalho",
        "en_us": "Select Image Header",
    },
    "Selecionar Imagem Menu" : {
        "pt_br": "Selecionar Imagem Menu",
        "en_us": "Select Image Menu",
    },
    "Imagem Login" : {
        "pt_br": "Imagem Login",
        "en_us": "Image Login",
    },
    "Imagem Cabeçalho" : {
        "pt_br": "Imagem Cabeçalho",
        "en_us": "Image Header",
    },
    "Imagem Menu" : {
        "pt_br": "Imagem Menu",
        "en_us": "Image Menu",
    },
    "Campo obrigatório*" : {
        "pt_br": "Campo obrigatório*",
        "en_us": "Required field*",
    },
    "Salvar" : {
        "pt_br": "Salvar",
        "en_us": "Save",
    },
    "Visualizar" : {
        "pt_br": "Visualizar",
        "en_us": "View",
    },
    "Editar" : {
        "pt_br": "Editar",
        "en_us": "Edit",
    },
    "Deletar" : {
        "pt_br": "Deletar",
        "en_us": "Delete",
    },
    "Excluir" : {
        "pt_br": "Excluir",
        "en_us": "Delete",
    },
    "Excluir registro?" : {
        "pt_br": "Excluir registro?",
        "en_us": "Delete record?",
    },
    "Recuperar registro?" : {
        "pt_br": "Recuperar registro?",
        "en_us": "Recover record?",
    },
    "Lixeira" : {
        "pt_br": "Lixeira",
        "en_us": "Trash can",
    },
    "Recuperar" : {
        "pt_br": "Recuperar",
        "en_us": "Recover",
    },
    "Nova Permissão" : {
        "pt_br": "Nova Permissão",
        "en_us": "New Permission",
    },
    "Chave" : {
        "pt_br": "Chave",
        "en_us": "Key",
    },
    "Descrição" : {
        "pt_br": "Descrição",
        "en_us": "Description",
    },
    "Ativo" : {
        "pt_br": "Ativo",
        "en_us": "Active",
    },
    "Bloqueado" : {
        "pt_br": "Bloqueado",
        "en_us": "Blocked",
    },
    "Nova Empresa" : {
        "pt_br": "Nova Empresa",
        "en_us": "New Account",
    },
    "Telefone" : {
        "pt_br": "Telefone",
        "en_us": "Phone",
    },
    "Situação" : {
        "pt_br": "Situação",
        "en_us": "Status",
    },
    "Novo Tipo de Usuário" : {
        "pt_br": "Novo Tipo de Usuário",
        "en_us": "New Type of User",
    },
    "Grupo" : {
        "pt_br": "Grupo",
        "en_us": "Group",
    },
    "Atualizar" : {
        "pt_br": "Atualizar",
        "en_us": "Update",
    },
    'Operações': {
        "pt_br": "Operações",
        "en_us": "Operations",
    },
    'Armadores': {
        "pt_br": "Armadores",
        "en_us": "Shipowners",
    },
    'Embarcações': {
        "pt_br": "Embarcações",
        "en_us": "Ships",
    },
    'Portos': {
        "pt_br": "Portos",
        "en_us": "Ports",
    },
    'Companhias': {
        "pt_br": "Companhias",
        "en_us": "Companies",
    },
    'Situações': {
        "pt_br": "Situações",
        "en_us": "Status",
    },
    'Tipos de Carga': {
        "pt_br": "Tipos de Carga",
        "en_us": "Types of Cargo",
    },
    'Severidades': {
        "pt_br": "Severidades",
        "en_us": "Severities",
    },
    'Cor': {
        "pt_br": "Cor",
        "en_us": "Color",
    },
    'Finaliza Operação': {
        "pt_br": "Finaliza Operação",
        "en_us": "End Operation",
    },
    'Nova Situação': {
        "pt_br": "Nova Situação",
        "en_us": "New Status",
    },
    'Novo Armador': {
        "pt_br": "Novo Armador",
        "en_us": "New Shipowner",
    },
    'Nova Embarcação': {
        "pt_br": "Nova Embarcação",
        "en_us": "New Ship",
    },
    'Novo Porto': {
        "pt_br": "Novo Porto",
        "en_us": "New Port",
    },
    'Código': {
        "pt_br": "Código",
        "en_us": "Code",
    },
    'País': {
        "pt_br": "País",
        "en_us": "Country",
    },
    'Nova Companhia': {
        "pt_br": "Nova Companhia",
        "en_us": "New Company",
    },
    'Novo Tipo De Cargo': {
        "pt_br": "Novo Tipo De Cargo",
        "en_us": "New Type of Cargo",
    },
    'Categorias': {
        "pt_br": "Categorias",
        "en_us": "Categories",
    },
    'Partes': {
        "pt_br": "Partes",
        "en_us": "Parts",
    },
    'Avarias': {
        "pt_br": "Avarias",
        "en_us": "Breakdowns",
    },
    'Nova Categoria': {
        "pt_br": "Nova Categoria",
        "en_us": "New Category",
    },
    'Nova Parte': {
        "pt_br": "Nova Parte",
        "en_us": "New Part",
    },
    'Nova Avaria': {
        "pt_br": "Nova Avaria",
        "en_us": "New Breakdown",
    },
    'Editar Avaria': {
        "pt_br": "Atualizar Avaria",
        "en_us": "Update Breakdown",
    },
    'Excluir foto?': {
        "pt_br": "Excluir foto?",
        "en_us": "Delete photo?",
    },
    'Nova Carga': {
        "pt_br": "Nova Carga",
        "en_us": "New Cargo",
    },
    'Nova Operação': {
        "pt_br": "Nova Operação",
        "en_us": "New Operation",
    },
    'Embarcação': {
        "pt_br": "Embarcação",
        "en_us": "Ship",
    },
    'Mont./Export.': {
        "pt_br": "Mont./Export.",
        "en_us": "Ass./Export.",
    },
    'Rota': {
        "pt_br": "Rota",
        "en_us": "Route",
    },
    'Danificado': {
        "pt_br": "Danificado",
        "en_us": "Damaged",
    },
    'Coletado': {
        "pt_br": "Coletado",
        "en_us": "Collected",
    },
    'Não Coletado': {
        "pt_br": "Não Coletado",
        "en_us": "Not Collected",
    },
    'Ver Avarias': {
        "pt_br": "Ver Avarias",
        "en_us": "View Breakdowns",
    },
    'Todos': {
        "pt_br": "Todos",
        "en_us": "All",
    },
    'Procurar': {
        "pt_br": "Procurar",
        "en_us": "Search",
    },
    'Montadora': {
        "pt_br": "Montadora",
        "en_us": "Assembler",
    },
    'Exportadora': {
        "pt_br": "Exportadora",
        "en_us": "Exporter",
    },
    'Novo Campo': {
        "pt_br": "Novo Campo",
        "en_us": "New Field",
    },
    'Tamanho': {
        "pt_br": "Tamanho",
        "en_us": "Length",
    },
    'Tipo de Carga': {
        "pt_br": "Tipo de Carga",
        "en_us": "Type of Cargo",
    },
    'Categoria': {
        "pt_br": "Categoria",
        "en_us": "Category",
    },
    'Armador': {
        "pt_br": "Armador",
        "en_us": "Shipowner",
    },
    'Cargas': {
        "pt_br": "Cargas",
        "en_us": "Cargos",
    },
    'Tipo': {
        "pt_br": "Tipo",
        "en_us": "Type",
    },
    'Importação': {
        "pt_br": "Importação",
        "en_us": "Loading",
    },
    'Exportação': {
        "pt_br": "Exportação",
        "en_us": "Discharging",
    },
    'Qtde.': {
        "pt_br": "Qtde.",
        "en_us": "Qty.",
    },
    'Origem/Destino': {
        "pt_br": "Origem/Destino",
        "en_us": "Origin-Destination",
    },
    'Porto de Destino Final': {
        "pt_br": "Porto de Destino Final",
        "en_us": "Port Final Destination",
    },
    'Data Estimada de Partida': {
        "pt_br": "Data Estimada de Partida",
        "en_us": "Estimated Departure Date",
    },
    'Data Estimada de Chegada': {
        "pt_br": "Data Estimada de Chegada",
        "en_us": "Estimated Arrival Date",
    },
    'Campos': {
        "pt_br": "Campos",
        "en_us": "Fields",
    },
    'Etapas': {
        "pt_br": "Etapas",
        "en_us": "Steps",
    },
    'Locais Leitura': {
        "pt_br": "Locais Leitura",
        "en_us": "Reading Places",
    },
    'Locais de Leitura': {
        "pt_br": "Locais de Leitura",
        "en_us": "Reading Places",
    },
    'Local de Leitura': {
        "pt_br": "Local de Leitura",
        "en_us": "Reading Place",
    },
    'Local de leitura': {
        "pt_br": "Local de leitura",
        "en_us": "Reading place",
    },
    'Tipo de Leitura': {
        "pt_br": "Tipo de Leitura",
        "en_us": "Type of Reading",
    },
    'CPF/CNPJ (sem pontuação)': {
        "pt_br": "CPF/CNPJ (sem pontuação)",
        "en_us": "CPF/CNPJ (no punctuation)",
    },
    'Tipo de Operação': {
        "pt_br": "Tipo de Operação",
        "en_us": "Operation Type",
    },
    'Tipo de leitura': {
        "pt_br": "Tipo de leitura",
        "en_us": "Type of reading",
    },
    'Novo Local': {
        "pt_br": "Novo Local",
        "en_us": "New Local",
    },
    'Operações Cargas': {
        "pt_br": "Operações Cargas",
        "en_us": "Cargo Operations",
    },
    'Operações Portos': {
        "pt_br": "Operações Portos",
        "en_us": "Port Operations",
    },
    'Peças': {
        "pt_br": "Peças",
        "en_us": "Parts",
    },
    'Total Chassis': {
        "pt_br": "Total Chassis",
        "en_us": "Total Chassis",
    },
    'Total Coletados': {
        "pt_br": "Total Coletados",
        "en_us": "Total Collected",
    },
    'Gerar Relatório': {
        "pt_br": "Gerar Relatório",
        "en_us": "Generate report",
    },
    'Realizar Inspeção': {
        "pt_br": "Realizar Inspeção",
        "en_us": "Perform Inspection",
    },
    'Acompanhar Inspeção': {
        "pt_br": "Acompanhar Inspeção",
        "en_us": "Follow Inspection",
    },
    'Operações em andamento': {
        "pt_br": "Operações em andamento",
        "en_us": "Ongoing Operations",
    },
    'Operação em andamento': {
        "pt_br": "Operação em andamento",
        "en_us": "Ongoing Operation",
    },
    'Coletar Chassi': {
        "pt_br": "Coletar Chassi",
        "en_us": "Collect Chassi",
    },
    'Outras Etapas': {
        "pt_br": "Outras Etapas",
        "en_us": "Other Steps",
    },
    'Etapas da Operação': {
        "pt_br": "Etapas da Operação",
        "en_us": "Operation Steps",
    },
    'Importar': {
        "pt_br": "Importar",
        "en_us": "Import",
    },
    'Categoria da Carga': {
        "pt_br": "Categoria da Carga",
        "en_us": "Cargo Category",
    },
    'Selecionar arquivo': {
        "pt_br": "Selecionar arquivo",
        "en_us": "Select file",
    },
    'Coluna Exportadora': {
        "pt_br": "Coluna Exportadora",
        "en_us": "Export Column",
    },
    'Coluna Montadora': {
        "pt_br": "Coluna Montadora",
        "en_us": "Assembler Column",
    },
    'Coluna POL': {
        "pt_br": "Coluna POL",
        "en_us": "POL Column",
    },
    'Coluna POD': {
        "pt_br": "Coluna POD",
        "en_us": "POD Column",
    },
    'Coluna PFD': {
        "pt_br": "Coluna PFD",
        "en_us": "PFD Column",
    },
    'Coluna Chassi': {
        "pt_br": "Coluna Chassi",
        "en_us": "Chassi Column",
    },
    'Coluna': {
        "pt_br": "Coluna",
        "en_us": "Column",
    },
    'Nova Etapa': {
        "pt_br": "Nova Etapa",
        "en_us": "New Step",
    },
    'Controle': {
        "pt_br": "Controle",
        "en_us": "Control",
    },
    'Subtipo de Leitura': {
        "pt_br": "Subtipo de Leitura",
        "en_us": "Subtype of Reading",
    },
    'Subtipos de Leitura': {
        "pt_br": "Subtipos de Leitura",
        "en_us": "Subtypes of Reading",
    },
    'Lista de fotos': {
        "pt_br": "Lista de fotos",
        "en_us": "Photo List",
    },
    'Nenhuma foto coletada': {
        "pt_br": "Nenhuma foto coletada",
        "en_us": "No photo collected",
    },
    'Enviar fotos': {
        "pt_br": "Enviar fotos",
        "en_us": "Send photos",
    },
    'Fechar': {
        "pt_br": "Fechar",
        "en_us": "Close",
    },
    'Operação': {
        "pt_br": "Operação",
        "en_us": "Operation",
    },
    'Iniciar Coleta': {
        "pt_br": "Iniciar Coleta",
        "en_us": "Start Collection",
    },
    'Selecionar fotos': {
        "pt_br": "Selecionar fotos",
        "en_us": "Select photos",
    },
    'Qtde. Lida/Mix': {
        "pt_br": "Qtde. Lida/Mix",
        "en_us": "Qty. Read/Mix",
    },
    'Última Leitura': {
        "pt_br": "Última Leitura",
        "en_us": "Last Read",
    },
    'Usar Leitor': {
        "pt_br": "Usar Leitor",
        "en_us": "Scanner",
    },
    'Digitar': {
        "pt_br": "Digitar",
        "en_us": "Search",
    },
    'Indisponível': {
        "pt_br": "Indisponível",
        "en_us": "Unavailable",
    },
    'Leitor': {
        "pt_br": "Leitor",
        "en_us": "Scanner",
    },
    'Leitura': {
        "pt_br": "Leitura",
        "en_us": "Reading",
    },
    'Chassis Lidos': {
        "pt_br": "Chassis Lidos",
        "en_us": "Chassis Read",
    },
    'Procurar Chassi': {
        "pt_br": "Procurar Chassi",
        "en_us": "Search Chassi",
    },
    'Preencha os dados': {
        "pt_br": "Preencha os dados",
        "en_us": "Fill in the data",
    },
    'Nenhuma avaria encontrada': {
        "pt_br": "Nenhuma avaria encontrada",
        "en_us": "No breakdown found",
    },
    'Nenhum chassi encontrado': {
        "pt_br": "Nenhum chassi encontrado",
        "en_us": "No chassi found",
    },
    'Nenhum item encontrado': {
        "pt_br": "Nenhum item encontrado",
        "en_us": "No iten found",
    },
    'Filtros': {
        "pt_br": "Filtros",
        "en_us": "Filters",
    },
    'Detalhes': {
        "pt_br": "Detalhes",
        "en_us": "Details",
    },
    'Detalhe': {
        "pt_br": "Detalhe",
        "en_us": "Detail",
    },
    'Modelo': {
        "pt_br": "Modelo",
        "en_us": "Model",
    },
    'Relatório': {
        "pt_br": "Relatório",
        "en_us": "Report",
    },
    'Lista de Chassi': {
        "pt_br": "Lista de Chassi",
        "en_us": "Chassis List",
    },
    'Estatísticas': {
        "pt_br": "Estatísticas",
        "en_us": "Statistics",
    },
    'Informações Gerais': {
        "pt_br": "Informações Gerais",
        "en_us": "General information",
    },
    'Por tipo de operação': {
        "pt_br": "Por tipo de operação",
        "en_us": "By Type Operation",
    },
    'Por Porto': {
        "pt_br": "Por porto",
        "en_us": "By Port",
    },
    'Parte da Carga': {
        "pt_br": "Parte da Carga",
        "en_us": "Part",
    },
    'Parte': {
        "pt_br": "Parte",
        "en_us": "Part",
    },
    'Avaria': {
        "pt_br": "Avaria",
        "en_us": "Breakdown",
    },
    'Severidade': {
        "pt_br": "Severidade",
        "en_us": "Severity",
    },
    'Imagens': {
        "pt_br": "Imagens",
        "en_us": "Photos",
    },
}

export const setLanguage = (value) => {
    localStorage.setItem('LANGUAGE', value);
    window.location.reload()
}

export const getLanguage = () => {
    return (localStorage.getItem('LANGUAGE')) ? localStorage.getItem('LANGUAGE') : 'pt_br';
}

export const getLanguageUpper = () => {
    return (localStorage.getItem('LANGUAGE')) ? String(localStorage.getItem('LANGUAGE')).toUpperCase() : 'PT_BR';
}

export const _t = (value) => {
    try {
        return values[value][getLanguage()]
    } catch (error) {
        return value
    }
}

export const _p = (value) => {
    try {
        return (value[getLanguage()] !== undefined) ? value[getLanguage()] : '_p(:ERROR)'
    } catch (error) {
        return '_p(:ERROR)'
    }
}