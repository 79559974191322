
var LOGO_LOGIN = '';
var LOGO_NAVBAR = '';
var LOGO_SIDEBAR = '';
var FOOTER = '';
var COLOR = '#7692af';

export const setLogoLogin = (value) => { LOGO_LOGIN = value }
export const getLogoLogin = () => { return LOGO_LOGIN }
export const setLogoNavbar = (value) => { LOGO_NAVBAR = value }
export const getLogoNavbar = () => { return LOGO_NAVBAR }
export const setLogoSidebar = (value) => { LOGO_SIDEBAR = value }
export const getLogoSidebar = () => { return LOGO_SIDEBAR }
export const setFooter = (value) => { FOOTER = value }
export const getFooter = () => { return FOOTER }
export const setColor = (value) => { COLOR = value }
export const getColor = () => { return COLOR }

export const ENV_ID = 2;
export const URL_API = "https://sistem.inspectorcarga.com.br/services/php";
export const UPLOAD_API = "https://sistem.inspectorcarga.com.br/services/php/upload/upload.php";

export const setToken = (value) => {
    localStorage.setItem('TOKEN', value);
}

export const getToken = () => {
    return localStorage.getItem('TOKEN');
}

export const delToken = () => {
    localStorage.removeItem('TOKEN')
}

export const setConfig = (value) => {
    localStorage.setItem('CONFIG', value);
}

export const getConfig = () => {
    return localStorage.getItem('CONFIG');
}

export const delConfig = () => {
    localStorage.removeItem('CONFIG')
}

export const verifyConfig = (value) => {
    var config = JSON.parse(getConfig())
    return (config[value] === 'T')
}

export const POST_API = (url, data) => {

    function createFormData () {
        const form = new FormData()
    
        Object.keys(data).forEach(key => {
            form.append(key, data[key]);
        });
      
        return form;
    };
  
    return fetch(URL_API+url, { method: "post", body: createFormData() })

}
