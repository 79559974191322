import React from "react";
import { Breadcrumb, Button, Col, Form, Input, Layout, message, Row, Switch } from 'antd';
import { Link, Navigate } from "react-router-dom";
import { MenuItem, TextField } from "@mui/material";
import InputMask from 'react-input-mask';

// API
import { getToken, POST_API, verifyConfig } from "../../../services/server-api";

// TRANSLATE
import { _p, _t } from "../../../services/language";

// CONTROLLER
import Page403 from '../../Controller/Page403';

// PERMISSIONS
import { _ADD } from "./_permission";

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        _cpfcnpj: false,
        defaultValue: {
            ID: 0
        }
    }

    componentDidMount = () => {
        this.setState({_loading: false})
    }

    onSend = (values) => {
        this.setState({_loading: true})
        POST_API('/account/save.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: _p(res.msg), key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        }).finally(() => this.setState({_loading: false}))
    }

    onCPFCNPJ = (value) => {
        this.setState({_cpfcnpj: value})
    }

    render = () => {

        const { Content } = Layout

        if (this.state._navigate) {
            return (<Navigate to={'/dashboard/account/'} />)
        }

        if (getToken() == null || !verifyConfig(_ADD)) {
            return (<Page403 />)
        }

        return (
            <Content className="sys-container">
                <Row gutter={[8,8]}>
                    <Col span={20}>
                        <Breadcrumb className="sys-breadcrumb">
                            <Breadcrumb.Item><Link to="/dashboard/account">{_t('Empresas')}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>{_t('Adicionar')}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={4}>
                        <Link to="/dashboard/account"><Button type="primary" className="sys-btn-primary sys-btn-small float-right">{_t('Voltar')}</Button></Link>
                    </Col>
                    <Col span={24}>
                        <Form layout="vertical" className="sys-form" onFinish={this.onSend} initialValues={this.state.defaultValue}>
                            <Form.Item hidden name="ID">
                                <Input  />
                            </Form.Item>
                            <Row gutter={[8,0]}>
                                <Col xs={24} md={18}>
                                    <Form.Item name="NAME" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                        <TextField fullWidth variant="standard" label={_t("Nome")} type='text' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item name="EMAIL" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                        <TextField fullWidth variant="standard" label={_t("E-mail")} type='text' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item name="PHONE" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                        <InputMask mask="(99) 9999-99999">
                                            {(inputProps) => <TextField {...inputProps} fullWidth variant="standard" label={_t("Telefone")} type='text' />}
                                        </InputMask>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item name="CPFCNPJ" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                        <InputMask mask={!this.state._cpfcnpj ? "999.999.999-99" : "99.999.999/9999-99"}>
                                            {(inputProps) => <TextField {...inputProps} fullWidth variant="standard" label={_t("CPF/CNPJ")} type='text' ></TextField>}
                                        </InputMask>
                                    </Form.Item>
                                    <Switch onChange={this.onCPFCNPJ} size="small" className="sys-input-switch" checkedChildren="CNPJ" unCheckedChildren="CPF" />
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item name="STATUS" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                        <TextField select fullWidth variant="standard" label={_t("Situação")} type='text' >
                                            <MenuItem value="A">{_t('Ativo')}</MenuItem>
                                            <MenuItem value="B">{_t('Bloqueado')}</MenuItem>
                                        </TextField>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Button loading={this.state._loading} htmlType="submit" type="default" className="sys-btn-default float-right">{_t('Salvar')}</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;