import React from "react";
import { Breadcrumb, Button, Col, Form, Input, Layout, message, Row } from 'antd';
import { Link, Navigate } from "react-router-dom";
import { MenuItem, TextField } from "@mui/material";

// API
import { getToken, POST_API, verifyConfig } from "../../../services/server-api";

// TRANSLATE
import { _p, _t } from "../../../services/language";

// CONTROLLER
import Page403 from '../../Controller/Page403';
import PageLoad from '../../Controller/PageLoad';

// PERMISSIONS
import { _EDIT } from "./_permission";

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        _screen: false,
        defaultValue: {
            ID: 0,
        }
    }

    componentDidMount = () => {
        this.onLoadFilterTypeUser().then(() => this.onView())
    }

    onLoadFilterTypeUser = async () => {
        await POST_API('/typeuser/filter.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({filter__type_user: res.data})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
    }

    onView = () => {
        var params = window.location.href.split('/')
        message.loading({ content: _t('Carregando...'), key: 'screen' })
        POST_API('/credential/view.php', { token: getToken(), ID: params[5] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: _p(res.msg), key: 'screen' })
                this.setState({_screen: true, defaultValue: res.data})
            } else {
                message.warning({ content: _p(res.msg), key: 'screen' })
                this.setState({_navigate: true})
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
            this.setState({_navigate: true})
        }).finally(() => this.setState({_loading: false}))
    }

    onSend = (values) => {
        this.setState({_loading: true})
        POST_API('/credential/save.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: _p(res.msg), key: 'send' })
                this.onView()
            } else {
                message.warning({ content: _p(res.msg), key: 'send' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'send' })
        }).finally(() => this.setState({_loading: false}))
    }

    render = () => {

        const { Content } = Layout

        if (this.state._navigate) {
            return (<Navigate to={'/dashboard/user/'} />)
        }

        if (getToken() == null || !verifyConfig(_EDIT)) {
            return (<Page403 />)
        }

        return (
            <Content className="sys-container">
                <Row gutter={[8,8]}>
                    <Col span={20}>
                        <Breadcrumb className="sys-breadcrumb">
                            <Breadcrumb.Item><Link to="/dashboard/user">{_t('Usuários')}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>{_t('Atualizar')}</Breadcrumb.Item>
                            <Breadcrumb.Item>{this.state.defaultValue.ID}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={4}>
                        <Link to="/dashboard/user"><Button type="primary" className="sys-btn-primary sys-btn-small float-right">{_t('Voltar')}</Button></Link>
                    </Col>
                    <Col span={24}>
                    {this.state._screen ? (
                        <Form layout="vertical" className="sys-form" onFinish={this.onSend} initialValues={this.state.defaultValue} defaultValue={this.state.defaultValue}>
                            <Form.Item hidden name="ID">
                                <Input  />
                            </Form.Item>
                            <Row gutter={[8,0]}>
                                <Col xs={24} md={6}>
                                    <Form.Item name="NAME" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                        <TextField fullWidth variant="standard" label={_t("Nome")} type='text' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item name="LOGIN" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                        <TextField fullWidth variant="standard" label={_t("E-mail")} type='text' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item name="TYPE_CREDENTIAL_ID" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                        <TextField select fullWidth variant="standard" label={_t("Tipo de Usuário")} type='text' >
                                            {this.state.filter__type_user.map((v,i) => (
                                                <MenuItem key={i} value={v.value}>{v.text}</MenuItem>
                                            ))}
                                        </TextField>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item name="STATUS" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                        <TextField select fullWidth variant="standard" label={_t("Situação")} type='text' >
                                            <MenuItem value="A">{_t('Ativo')}</MenuItem>
                                            <MenuItem value="B">{_t('Bloqueado')}</MenuItem>
                                        </TextField>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Button loading={this.state._loading} htmlType="submit" type="default" className="sys-btn-default float-right">{_t('Salvar')}</Button>
                                </Col>
                            </Row>
                        </Form>
                    ) : <PageLoad /> }
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;