import React from "react";
import { Breadcrumb, Button, Col, Form, Input, Layout, message, Row } from 'antd';
import { Link, Navigate } from "react-router-dom";
import { MenuItem, TextField } from "@mui/material";

// API
import { getToken, POST_API, verifyConfig } from "../../../../services/server-api";

// TRANSLATE
import { getLanguageUpper, _p, _t } from "../../../../services/language";

// CONTROLLER
import Page403 from '../../../Controller/Page403';

// PERMISSIONS
import { _ADD } from "./_permission";

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        _params: window.location.href.split('/'),
        defaultValue: {
            ID: 0,
            OPERATION_PORT_ID: ''
        },
        _load_operation_port: [],
        _load_fields: [],
        _chassis: [1],
    }

    componentDidMount = () => {
        this.onLoadOptions().finally(() => this.setState({_loading: false}))
    }

    onLoadOptions = async () => {
        await POST_API('/operation/port/filter.php', { token: getToken(), OPERATION_ID: this.state._params[5] }).then(rs => rs.json()).then(res => {
            if (res.return) this.setState({_load_operation_port: res.data})
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
    }

    onLoadFields = async(value) => {
        await POST_API('/typecargo/field/filter.php', { token: getToken(), OPERATION_PORT_ID: value.target.value }).then(rs => rs.json()).then(res => {
            if (res.return) this.setState({_load_fields: res.data})
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
    }

    onSend = (values) => {
        this.setState({_loading: true})

        values.OPERATION_ID = this.state._params[5]

        POST_API('/operation/cargo/save.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: _p(res.msg), key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        }).finally(() => this.setState({_loading: false}))
    }

    AddChassi = () => {
        var temp = this.state._chassis;
        temp.push((temp.length+1));
        this.setState({_chassis: temp})
    }

    render = () => {

        const { Content } = Layout

        if (this.state._navigate) {
            return (<Navigate to={`/dashboard/operation/${this.state._params[5]}/cargo/`} />)
        }

        if (getToken() == null || !verifyConfig(_ADD)) {
            return (<Page403 />)
        }

        return (
            <Content className="sys-container">
                <Row gutter={[8,8]}>
                    <Col span={20}>
                        <Breadcrumb className="sys-breadcrumb">
                            <Breadcrumb.Item><Link to="/dashboard/operation">{_t('Operações')}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>{this.state._params[5]}</Breadcrumb.Item>
                            <Breadcrumb.Item><Link to={`/dashboard/operation/${this.state._params[5]}/cargo/`}>{_t('Cargas')}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>{_t('Adicionar')}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={4}>
                        <Link to={`/dashboard/operation/${this.state._params[5]}/cargo/`}><Button type="primary" className="sys-btn-primary sys-btn-small float-right">{_t('Voltar')}</Button></Link>
                    </Col>
                    <Col span={24}>
                        <Form layout="vertical" className="sys-form" onFinish={this.onSend} initialValues={this.state.defaultValue}>
                            <Row gutter={[8,0]}>
                                <Col xs={24} md={24}>
                                    <Form.Item name="OPERATION_PORT_ID" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                        <TextField onChange={this.onLoadFields} select fullWidth variant="standard" label={_t("Tipo de Carga")} type='text' >
                                            <MenuItem value="" disabled>{`${_t('Montadora')}/${_t('Exportadora')} - ${_t('Tipo de Carga')}/${'Categoria'} - POL > POD > PFD`}</MenuItem>
                                            {this.state._load_operation_port.map((v,i) => (
                                                <MenuItem value={v.ID} k={i}>{_t(v.TYPE_NAME)} | {v.ASSEMBLER_NAME}/{v.ASSEMBLER_NAME} - {v['TYPE_CARGO_NAME_'+getLanguageUpper()]}/{v['CATEGORY_NAME_'+getLanguageUpper()]} - {v.POL_NAME} {'>'} {v.POD_NAME} {'>'} {v.PFD_NAME}</MenuItem>
                                            ))}
                                        </TextField>
                                    </Form.Item>
                                </Col>
                                {this.state._chassis.map((v,i) => (
                                    <Col span={24} key={i} >
                                        <Row gutter={[8,0]}>
                                            <Form.Item hidden name={`ID_${i}`}>
                                                <Input  />
                                            </Form.Item>
                                            <Col xs={24} md={12}>
                                                <Form.Item name={`CHASSI_${i}`} rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                                    <TextField fullWidth variant="standard" label={_t("Chassi")} type='text' />
                                                </Form.Item>
                                            </Col>
                                            {this.state._load_fields.map((v1,i1) => (
                                                <Col xs={24} md={Number(v1.LENGTH)} key={i1}>
                                                    <Form.Item name={`FIELD_${i}_${v1.ID}`} rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                                        <TextField fullWidth variant="standard" label={v1['NAME_'+getLanguageUpper()]} type='text' />
                                                    </Form.Item>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Col>
                                ))}
                                <Col xs={24} md={24}>
                                    <Button loading={this.state._loading} htmlType="submit" type="primary" style={{marginLeft: 5}} className="sys-btn-primary float-right">{_t('Salvar')}</Button>
                                    <Button onClick={this.AddChassi} type="default" className="sys-btn-default float-right">{_t('Novo Chassi')}</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;