import React from "react";
import { Button, Col, Form, Image, Layout, message, Row } from 'antd';
import { TextField } from '@mui/material';
import { Navigate } from "react-router-dom";
import { getToken, POST_API, setToken, getLogoLogin, setConfig } from "../../services/server-api";
import Language from '../../components/Widgets/Language';
import { _t, _p } from "../../services/language";

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: true
    }

    componentDidMount = () => {
        if (getToken()) {
            message.loading({ content: _t('Carregando...'), key: 'login' })
            POST_API('/shipowner/verify.php', { token: getToken() }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({_navigate: true})
                } else {
                    message.warning({ content: _p(res.msg), key: 'login' })
                }
            }).catch(() => {
                message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'login' })
            }).finally(() => {
                this.setState({_loading: false})
            })
        }
        this.setState({_loading: false})
    }

    onSend = (values) => {

        this.setState({_loading: true})
        message.loading({ content: _t('Carregando...'), key: 'login' })

        POST_API('/shipowner/login.php', { token: 'X', master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setToken(res.token)
                setConfig("{}")
                message.success({ content: _p(res.msg), key: 'login' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: _p(res.msg), key: 'login' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'login' })
        }).finally(() => {
            this.setState({_loading: false})
        })

    }

    render = () => {

        const { Content } = Layout
        const logo = getLogoLogin()

        if (this.state._navigate) {
            return (<Navigate to="dashboard" />);
        }

        return (
            <Content>
                <Row style={{height: '100vh', alignItems: 'center'}} justify="center">
                    <Col className="sys-all-center sys-flex-column" style={{height: 300}} xs={20} md={6}>
                        <Image src={logo} preview={false} width={175} />
                        <Form layout="vertical" onFinish={this.onSend}>
                            <Form.Item name="LOGIN">
                                <TextField fullWidth variant="standard" label={_t("CPF/CNPJ (sem pontuação)")} />
                            </Form.Item>
                            <Form.Item name="PASSWORD">
                                <TextField fullWidth type={'password'} variant="standard" label={_t("Senha")} />
                            </Form.Item>
                            <Form.Item>
                                <Button loading={this.state._loading} className="sys-btn-primary" htmlType="submit" block type="primary">Login</Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <Language />
            </Content>
        )
    }

}

export default Screen;