import React from "react";
import { Button, Card, Col, Radio, Form, Layout, message, Modal, Pagination, Progress, Row, Select, Space, Spin, Typography, Upload } from 'antd';
import { Link, Navigate } from "react-router-dom";

// API
import { getToken, POST_API, UPLOAD_API } from "../../../services/server-api";

// TRANSLATE
import { getLanguageUpper, _p, _t } from "../../../services/language";

// CONTROLLER
import Page403 from '../../Controller/Page403';
import { MenuItem, TextField } from "@mui/material";
import { IoBarcode, IoBarcodeOutline } from "react-icons/io5";
import AntdImgCrop from "antd-img-crop";

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        _loading_button: false,
        _screen: false,
        _modal: false,
        disabled: true,
        _params: window.location.href.split('/'),
        defaultValue: {
            ID: 0
        },
        defaultValueCargo: {
            ID: 0,
        },
        values: {
            coords: {latitude: 'Indisponível', longitude: 'Indisponível'},
            last: '-'
        },
        avarias: [],
        _pagination: {
            current: 1,
            total: 0,
            limit: 10
        },
        search: "",
        _list_part: [],
        _list_breakdown: [],
        _list_severity: [],
    }

    componentDidMount = () => {
        this.onView()
        this.loadOption().finally(() => this.setState({_loading: false}))
    }

    GeoLocalSuccess = (value) => {
        this.setState({values: {...this.state.values, coords: value.coords}})
    }
    GeoLocalError = (value) => {
        this.setState({values: {...this.state.values, coords: {latitude: 'Indisponível', longitude: 'Indisponível'}}})
        message.error({ content: `Geolocalização indisponível no momento`, key: 'screen' })
    }

    loadOption = async () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.watchPosition(this.GeoLocalSuccess, this.GeoLocalError, {enableHighAccuracy: false, timeout: 3000, maximumAge: 0});
        } else {
            message.error({ content: _t('Geolocalização indisponível para o seu navegador.'), key: 'screen' })
            this.setState({values: {...this.state.values, coords: {latitude: 'Indisponível', longitude: 'Indisponível'}}})
        }
        await POST_API('/typecargo/view.php', { token: getToken(), ID: this.state._params[10] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({values: {...this.state.values, CARGO_TYPE: res.data}})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
        await POST_API('/operation/cargo/search.php', { token: getToken(), filter: `{"STATUS":"S","OPERATION_ID":"${this.state._params[5]}","TYPE_READING_ID":"${this.state._params[8]}","TYPE_CARGO_ID":"${this.state._params[10]}"}`, sorter: `{"columnKey":"DATETIME_COLLECT","order":"descend"}` }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({ values: { ...this.state.values, LIDO: res.summary.QTDE }})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        }).finally(() => {
            
        })
        await POST_API('/operation/cargo/search.php', { token: getToken(), filter: `{"OPERATION_ID":"${this.state._params[5]}","TYPE_READING_ID":"${this.state._params[8]}","TYPE_CARGO_ID":"${this.state._params[10]}"}` }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({ values: { ...this.state.values, MIX: res.summary }})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
        await POST_API('/operation/cargo/search.php', { token: getToken(), filter: `{"OPERATION_ID":"${this.state._params[5]}","TYPE_READING_ID":"${this.state._params[8]}","TYPE_CARGO_ID":"${this.state._params[10]}"}` }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({ values: { ...this.state.values, MIX: res.summary.QTDE }})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
        await POST_API('/typereading/view.php', { token: getToken(), ID: this.state._params[8] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({values: {...this.state.values, TYPE_READING: res.data}})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
        await POST_API('/localreading/view.php', { token: getToken(), ID: this.state._params[7] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({values: {...this.state.values, LOCAL_READING: res.data}})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
        await POST_API('/subtypereading/view.php', { token: getToken(), ID: this.state._params[9] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({values: {...this.state.values, SUBTYPE_READING: res.data}})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
        
    }

    onView = () => {
        var params = window.location.href.split('/')
        POST_API('/operation/view.php', { token: getToken(), ID: params[5] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                
                this.setState({_screen: true, defaultValue: res.data})
            } else {
                message.warning({ content: _p(res.msg), key: 'screen' })
                this.setState({_navigate: true})
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
            this.setState({_navigate: true})
        })
        POST_API('/operation/cargo/view.php', { token: getToken(), ID: params[11] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({_screen: true, defaultValueCargo: res.data})

                POST_API('/typecargo/part/search.php', { token: getToken(), TYPE_CARGO_ID: res.data.TYPE_CARGO_ID }).then(rs2 => rs2.json()).then(res2 => {
                    if (res2.return) {
                        this.setState({_list_part: res2.data})
                    } else {
                        message.warning({ content: _p(res2.msg), key: 'screen' })
                        this.setState({_navigate: true})
                    }
                }).catch(() => {
                    message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
                })

                POST_API('/typecargo/breakdown/search.php', { token: getToken(), TYPE_CARGO_ID: res.data.TYPE_CARGO_ID }).then(rs2 => rs2.json()).then(res2 => {
                    if (res2.return) {
                        this.setState({_list_breakdown: res2.data})
                    } else {
                        message.warning({ content: _p(res2.msg), key: 'screen' })
                        this.setState({_navigate: true})
                    }
                }).catch(() => {
                    message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
                })

                POST_API('/severity/search.php', { token: getToken() }).then(rs2 => rs2.json()).then(res2 => {
                    if (res2.return) {
                        this.setState({_list_severity: res2.data})
                    } else {
                        message.warning({ content: _p(res2.msg), key: 'screen' })
                        this.setState({_navigate: true})
                    }
                }).catch(() => {
                    message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
                })

                this.setState({disabled: false})

            } else {
                message.warning({ content: _p(res.msg), key: 'screen' })
                this.setState({_navigate: true})
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
            this.setState({_navigate: true})
        })
    }

    onSend = async (values) => {
        this.setState({_loading_button: true})

        values.OPERATION_ID = this.state._params[5];
        values.OPERATION_CARGO_ID = this.state._params[11];
        
        POST_API('/operation/cargo/breakdown/save.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: _p(res.msg), key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        }).finally(() => this.setState({_loading_button: false}))
    }

    onPaginationChange = async (page, size) => {
        await this.changePagination(page).then(() => {
            this.onRenderData()
        })
    }

    changePagination = async (page) => {
        this.setState({_pagination: {...this.state._pagination, current: page }})
    }

    changeSearch = async (e) => {
        this.setState({search: e.target.value})
    }

    onSearch = async (e) => {
        await this.changeSearch(e).then(() => this.onRenderData())
    }

    render = () => {

        const { Content } = Layout

        if (getToken() == null) {
            return (<Page403 />)
        }

        if (this.state._navigate) {
            return (<Navigate to={`/dashboard/inspector/${this.state._params[5]}/collect/${this.state._params[7]}/${this.state._params[8]}/${this.state._params[9]}/${this.state._params[10]}/${this.state._params[11]}`} />)
        }

        return (
            <Content className="sys-container">
                <Row gutter={[8,8]} justify="center">
                    <Col span={24}>
                        <Typography className="sys-title">- {_t('Nova Avaria')} -</Typography>
                    </Col>
                    {this.state._loading ? <Spin/> : (
                        <Col xs={24}>
                            <Row gutter={[8,8]}>
                                <Col span={24}>
                                    <Card size="small" hoverable className="sys-card-operation" title={`#${this.state.defaultValue.ID} ${this.state.defaultValue.SHIP_NAME} - ${this.state.defaultValue.PFD_NAME}`}>
                                        <Row gutter={[4,4]} style={{alignItems: 'center'}}>
                                            <Col span={12}><Typography><span className="sys-card-operation-title">{_t('Operação')}:</span> {this.state.values?.TYPE_READING[`NAME_${getLanguageUpper()}`]}</Typography></Col>
                                            <Col span={12}><Typography><span className="sys-card-operation-title">{_t('Leitura')}:</span> {this.state.values?.SUBTYPE_READING[`NAME_${getLanguageUpper()}`]}</Typography></Col>
                                            <Col span={12}><Typography><span className="sys-card-operation-title">{_t('Tipo de Carga')}:</span> {(this.state.values?.CARGO_TYPE[`NAME_${getLanguageUpper()}`]) ? this.state.values?.CARGO_TYPE[`NAME_${getLanguageUpper()}`] : ''}</Typography></Col>
                                            <Col span={12}><Typography><span className="sys-card-operation-title">{_t('Local')}:</span> {this.state.values?.LOCAL_READING[`NAME_${getLanguageUpper()}`]}</Typography></Col>
                                            <Col span={24}><Typography><span className="sys-card-operation-title">{_t('Qtde. Lida/Mix')}:</span> {this.state.values?.LIDO}/{this.state.values?.MIX} </Typography></Col>
                                            <Col span={12}><Typography><span className="sys-card-operation-title">Lat.:</span> {_t(this.state.values.coords.latitude)}</Typography></Col>
                                            <Col span={12}><Typography><span className="sys-card-operation-title">Long.:</span> {_t(this.state.values.coords.longitude)}</Typography></Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col span={24}>
                                    <Card size="small" style={{borderTopColor: this.state.defaultValueCargo?.POD_COLOR, borderTopWidth: 10, borderTopStyle: 'solid'}} hoverable className="sys-card-operation">
                                        <Row gutter={[4,0]} style={{alignItems: 'center'}}>
                                            <Col span={16}><Typography><span className="sys-card-operation-title">Vin Chassi:</span></Typography></Col>
                                            <Col span={8}><Typography><span className="sys-card-operation-title">{_t('Categoria')}:</span></Typography></Col>
                                            <Col span={16}><Typography><span>{this.state.defaultValueCargo?.CHASSI}</span></Typography></Col>
                                            <Col span={8}><Typography><span>{this.state.defaultValueCargo[`CATEGORY_NAME_`+getLanguageUpper()]}</span></Typography></Col>
                                            <Col span={24}><Typography><span className="sys-card-operation-title">POD:</span></Typography></Col>
                                            <Col span={24}><Typography><span>{this.state.defaultValueCargo?.POD_NAME}</span></Typography></Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col span={24}>
                                    <Typography className="sys-title">- {_t('Preencha os dados')} -</Typography>
                                </Col>
                                <Col span={24}>
                                    <Form layout="horizontal" onFinish={this.onSend}>
                                        <Row gutter={[8,8]}>
                                            <Col span={24}>
                                                <Card size="small" title={_t('Parte da Carga')} hoverable className="sys-card-operation">
                                                    {this.state._list_part.length > 0 ? (
                                                        <Form.Item name="PART_ID" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                                            <Radio.Group>
                                                                <Space direction="vertical">
                                                                    {this.state._list_part.map((v,i) => (
                                                                        <Radio key={i} value={v.ID}>{v[`NAME_${getLanguageUpper()}`]}</Radio>
                                                                    ))}
                                                                </Space>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    ) : null }
                                                </Card>
                                            </Col>
                                            <Col span={24}>
                                                <Card size="small" title={_t('Avaria')} hoverable className="sys-card-operation">
                                                    {this.state._list_breakdown.length > 0 ? (
                                                        <Form.Item name="BREAKDOWN_ID" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                                            <Radio.Group>
                                                                <Space direction="vertical">
                                                                    {this.state._list_breakdown.map((v,i) => (
                                                                        <Radio key={i} value={v.ID}>{v[`NAME_${getLanguageUpper()}`]}</Radio>
                                                                    ))}
                                                                </Space>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    ) : null }
                                                </Card>
                                            </Col>
                                            <Col span={24}>
                                                <Card size="small" title={_t('Severidade')} hoverable className="sys-card-operation">
                                                    {this.state._list_severity.length > 0 ? (
                                                        <Form.Item name="SEVERITY_ID" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                                            <Radio.Group>
                                                                <Space direction="vertical">
                                                                    {this.state._list_severity.map((v,i) => (
                                                                        <Radio key={i} value={v.ID}>{v[`NAME_${getLanguageUpper()}`]}</Radio>
                                                                    ))}
                                                                </Space>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    ) : null }
                                                </Card>
                                            </Col>
                                            <Col span={24}>
                                                <Card size="small" title={_t('Imagens')} hoverable className="sys-card-operation">
                                                    <Form.Item name="IMAGENS">
                                                        {/* <AntdImgCrop rotate> */}
                                                            <Upload
                                                                action={UPLOAD_API}
                                                                listType="picture-card"
                                                                onPreview={() => {}}
                                                            >
                                                                + Upload
                                                            </Upload>
                                                        {/* </AntdImgCrop> */}
                                                    </Form.Item>
                                                </Card>
                                            </Col>
                                            <Col span={24}>
                                                <Button loading={this.state._loading_button} disabled={this.state.disabled} htmlType="submit" type="primary" className="sys-btn-primary" block>{_t('Salvar')}</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                        </Col>
                    )}
                </Row>
            </Content>
        )
    }

}

export default Screen;