import React from "react";
import { Breadcrumb, Button, Col, Form, Input, Layout, message, Row } from 'antd';
import { Link, Navigate } from "react-router-dom";
import { MenuItem, TextField } from "@mui/material";

// API
import { getToken, POST_API, verifyConfig } from "../../../services/server-api";

// TRANSLATE
import { getLanguageUpper, _p, _t } from "../../../services/language";

// CONTROLLER
import Page403 from '../../Controller/Page403';

// PERMISSIONS
import { _ADD } from "./_permission";

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        paths: [],
        defaultValue: {
            ID: 0
        },
        _load_status: [],
        _load_ship: [],
        _load_shipowner: [],
        _load_port: [],
    }

    componentDidMount = () => {
        this.onLoadOptions().finally(() => this.setState({_loading: false}))
    }

    onLoadPath = async () => {
        POST_API('/path/filter.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({ paths: res.data })
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        }).finally(() => this.setState({load: false})); 
    }

    onLoadOptions = async () => {
        this.onLoadPath()
        await POST_API('/status/filter.php', { token: getToken(), lg: getLanguageUpper() }).then(rs => rs.json()).then(res => {
            if (res.return) this.setState({_load_status: res.data})
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
        await POST_API('/ship/filter.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            if (res.return) this.setState({_load_ship: res.data})
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
        await POST_API('/shipowner/filter.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            if (res.return) this.setState({_load_shipowner: res.data})
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
        await POST_API('/port/filter.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            if (res.return) this.setState({_load_port: res.data})
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
    }

    onSend = (values) => {
        this.setState({_loading: true})
        POST_API('/operation/save.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: _p(res.msg), key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        }).finally(() => this.setState({_loading: false}))
    }

    render = () => {

        const { Content } = Layout

        if (this.state._navigate) {
            return (<Navigate to={'/dashboard/operation/'} />)
        }

        if (getToken() == null || !verifyConfig(_ADD)) {
            return (<Page403 />)
        }

        return (
            <Content className="sys-container">
                <Row gutter={[8,8]}>
                    <Col span={20}>
                        <Breadcrumb className="sys-breadcrumb">
                            <Breadcrumb.Item><Link to="/dashboard/operation">{_t('Operações')}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>{_t('Adicionar')}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={4}>
                        <Link to="/dashboard/operation"><Button type="primary" className="sys-btn-primary sys-btn-small float-right">{_t('Voltar')}</Button></Link>
                    </Col>
                    <Col span={24}>
                        <Form layout="vertical" className="sys-form" onFinish={this.onSend} initialValues={this.state.defaultValue}>
                            <Form.Item hidden name="ID">
                                <Input  />
                            </Form.Item>
                            <Row gutter={[8,0]}>
                                <Col xs={24} md={6}>
                                    <Form.Item name="COD" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                        <TextField  fullWidth variant="standard" label={_t("Código")} type='text' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={7}>
                                    <Form.Item name="SHIP_ID" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                        <TextField select fullWidth variant="standard" label={_t('Embarcação')} type='text'>
                                            {this.state._load_ship.map((v,i) => (
                                                <MenuItem value={v.value} k={i}>{v.text}</MenuItem>
                                            ))}
                                        </TextField>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={7}>
                                    <Form.Item name="SHIPOWNER_ID" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                        <TextField select fullWidth variant="standard" label={_t('Armador')} type='text'>
                                            {this.state._load_shipowner.map((v,i) => (
                                                <MenuItem value={v.value} k={i}>{v.text}</MenuItem>
                                            ))}
                                        </TextField>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={4}>
                                    <Form.Item name="PFD_ID" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                        <TextField select fullWidth variant="standard" label={_t('Porto de Destino Final')} type='text'>
                                            {this.state._load_port.map((v,i) => (
                                                <MenuItem value={v.ID} k={i}>{v.COD}</MenuItem>
                                            ))}
                                        </TextField>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item name="STATUS_ID" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                        <TextField select fullWidth variant="standard" label={_t('Situação')} type='text'>
                                            {this.state._load_status.map((v,i) => (
                                                <MenuItem value={v.value} k={i}>{v.text}</MenuItem>
                                            ))}
                                        </TextField>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item name="DATETIME_DEPARTURE" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                        <TextField InputLabelProps={{ shrink: true }} fullWidth variant="standard" label={_t("Data Estimada de Partida")} type='datetime-local' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item name="DATETIME_ARRIVAL" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                        <TextField InputLabelProps={{ shrink: true }} fullWidth variant="standard" label={_t("Data Estimada de Chegada")} type='datetime-local' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item name="PATH_ID">
                                        <TextField select fullWidth variant="standard" label={_t('Pasta')} type='text'>
                                            {this.state.paths.map((v,i) => (
                                                <MenuItem value={v.ID} k={i}>{v.NAME}</MenuItem>
                                            ))}
                                        </TextField>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Button loading={this.state._loading} htmlType="submit" type="default" className="sys-btn-default float-right">{_t('Salvar')}</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;