import React from "react";
import { Button, Card, Col, Divider, Form, Image, Layout, message, Modal, Pagination, Progress, Row, Select, Spin, Typography, Upload } from 'antd';
import { Link, Navigate } from "react-router-dom";
import { ExclamationCircleOutlined } from '@ant-design/icons';

// API
import { getToken, POST_API, UPLOAD_API } from "../../../services/server-api";

// TRANSLATE
import { getLanguageUpper, _p, _t } from "../../../services/language";

// CONTROLLER
import Page403 from '../../Controller/Page403';
import { Chip, MenuItem, TextField } from "@mui/material";
import { IoClose } from "react-icons/io5";

class Screen extends React.Component {

    state = {
        _loading_button: false,
        _navigate: false,
        _loading: true,
        _screen: false,
        _modal: false,
        _params: window.location.href.split('/'),
        defaultValue: {
            ID: 0
        },
        defaultValueStep: {
            ID: 0
        },
        photos: []
    }

    componentDidMount = () => {
        this.onView()
        this.loadOption()
        this.onRenderData()
    }

    loadOption = () => {
        var params = window.location.href.split('/')
        POST_API('/step/view.php', { token: getToken(), ID: params[7] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({_screen: true, defaultValueStep: res.data})
            } else {
                message.warning({ content: _p(res.msg), key: 'screen' })
                this.setState({_navigate: true})
            }
        })
    }

    onView = () => {
        var params = window.location.href.split('/')
        POST_API('/operation/view.php', { token: getToken(), ID: params[5] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                
                this.setState({_screen: true, defaultValue: res.data})
            } else {
                message.warning({ content: _p(res.msg), key: 'screen' })
                this.setState({_navigate: true})
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
            this.setState({_navigate: true})
        })
    }

    onRenderData = async () => {
        await POST_API('/operation/step/search_photo.php', { token: getToken(), filter: `{"OPERATION_ID":"${this.state._params[5]}","STEP_ID":"${this.state._params[7]}"}` }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({ photos: res.data })
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        }).finally(() => { })
    }

    onSend = async (values) => {
        this.setState({_loading_button: true})

        values.OPERATION_ID = this.state._params[5];
        values.STEP_ID = this.state._params[7];

        POST_API('/operation/step/save_photo.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: _p(res.msg), key: 'screen' })
                this.onRenderData()
                this.setState({_navigate: true})
            } else {
                message.warning({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        }).finally(() => this.setState({_loading_button: false}))
    }

    onPaginationChange = async (page, size) => {
        await this.changePagination(page).then(() => {
            this.onRenderData()
        })
    }

    changePagination = async (page) => {
        this.setState({_pagination: {...this.state._pagination, current: page }})
    }

    changeSearch = async (e) => {
        this.setState({search: e.target.value})
    }

    onSearch = async (e) => {
        await this.changeSearch(e).then(() => this.onRenderData())
    }

    
    onDelete = (ID) => {
        var self = this
        Modal.confirm({
            title: _t('Excluir foto?'),
            icon: <ExclamationCircleOutlined />,
            cancelText: _t('Não'),
            okText: _t('Sim'),
            className: 'sys-modal-confirm',
            onOk() {
                POST_API('/operation/step/delete_photo.php', { token: getToken(), ID: ID }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: _p(res.msg), key: 'screen' })
                        self.onRenderData()
                    } else {
                        message.warning({ content: _p(res.msg), key: 'screen' })
                    }
                }).catch(() => {
                    message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
                }).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
    }

    render = () => {

        const { Content } = Layout

        if (getToken() == null) {
            return (<Page403 />)
        }

        return (
            <Content className="sys-container">
                <Row gutter={[8,8]} justify="center">
                    {!this.state._screen ? <Spin/> : (
                        <>
                            <Col span={24}>
                                <Typography className="sys-title">- {this.state.defaultValueStep[`NAME_${getLanguageUpper()}`]} -</Typography>
                            </Col>
                            <Col xs={24}>
                                <Form onFinish={this.onSend} layout="vertical">
                                    <Form.Item name="IMAGENS" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                        <Upload
                                            className="sys-upload"
                                            action={UPLOAD_API}
                                            multiple
                                            accept="image/png, image/gif, image/jpeg"
                                        >
                                            <Button type="primary" className="sys-btn-primary" block>{_t('Selecionar fotos')}</Button>
                                        </Upload>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button loading={this.state._loading_button} htmlType="submit" type="default" className="sys-btn-default" block>{_t('Enviar fotos')}</Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                            <Col xs={24} md={24}>
                                <Divider><Chip label={_t('Lista de fotos')}/></Divider>
                            </Col>
                            {this.state.photos.length > 0 ? (
                                <Col span={24}>
                                    <Row gutter={[8,8]}>
                                        {this.state.photos.map((v, i) => (
                                            <Col key={i} span={24}>
                                                <Button onClick={() => this.onDelete(v.ID)}  style={{position: 'absolute', zIndex: 1, marginLeft: 5, marginTop: 5}}  shape="circle" className="sys-btn-danger"><IoClose color="#FFFF" size={22} /></Button>
                                                <Image style={{borderRadius: 8}} src={v.URL} />
                                            </Col>
                                        ))}
                                    </Row>
                                </Col>

                            ) : (
                                <Col span={24}>
                                    <Typography style={{textAlign: 'center'}}>- {_t('Nenhuma foto coletada')} -</Typography>
                                </Col>
                            )}
                        </>
                    )}
                </Row>
            </Content>
        )
    }

}

export default Screen;