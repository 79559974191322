export const _ADD               = "k7PKoozQV4OIECs7n8TYSA==";
export const _EDIT              = "qt52MDYmNqtgl25Z/IuTJw==";
export const _VIEW              = "Co7spR7qM6Xt2yLCzOvZhA==";
export const _DELETE            = "VrGQvaz78O/tdc6xKU0A/fZ9RJf3Q+TlbUi636PR57I=";
export const _RECOVER           = "1Iq26Upp9PAj3VfXzkPDEuK1osoxeGVtoQJvZGjaepQ=";
export const _LIST              = "zCZFPCEGVez7fsSh5nJ8ug==";
export const _TRASH             = "YoAULJjRZV9rTvT1MCU1b2+Um/sx0+hdSZxQ06AtFio=";

export const _LIST_BREAKDOWN    = "ZlxxGkANENbDMiB24bvzSw==";
export const _LIST_CATEGORY     = "jwaMLwTashess85vRiV0Aw==";
export const _LIST_FIELD        = "IzQf4qdRNVqt8Y14hlIOTg==";
export const _LIST_PART         = "+nkLqd9L4hp5WCh7BHhRig==";

