import React from "react";
import { Button, Card, Carousel, Col, Form, Image, Layout, message, Modal, Pagination, Progress, Row, Spin, Tabs, Tag, Typography } from 'antd';

// API
import { getToken, POST_API, URL_API } from "../../../services/server-api";

// TRANSLATE
import { getLanguageUpper, _p, _t } from "../../../services/language";

// CONTROLLER
import Page403 from '../../Controller/Page403';
import Page500 from '../../Controller/Page500';
import { MenuItem, TextField } from "@mui/material";
import { IoArchiveOutline, IoConstructOutline } from "react-icons/io5";

class Screen extends React.Component {

    state = {
        select: '*',
        pfd: '*',
        _navigate: false,
        _loading: true,
        _params: window.location.href.split('/'),
        _loading_form: false,
        _filters: [],
        _screen: false,
        _modal: false,
        defaultValue: {
            ID: 0
        },
        formvalues: {},
        _list_local_reading: [],
        _list_type_reading: [],
        _list_sub_reading: [],
        _list_type_cargo: [],
        TYPE_READING_ID: '*',
        LOCAL_READING_ID: '*',
        // TAB 2
        _load_port: [],
        _load_mont: [],
        _load_expo: [],
        _list: [],
        _breakdown: [],
        _breakdown_loading: true,
        _pagination: {
            current: 1,
            total: 0,
            limit: 10
        },
    }

    loop = null;

    componentDidMount = () => {
        this.onView().then(() => {
            this.loop = setInterval(() => {
                this.onView()
            }, 5000);
        })
        POST_API('/port/filter.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            if (res.return) this.setState({_load_port: res.data})
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
         var params = window.location.href.split('/')
        POST_API('/operation/montadora-filter.php', { token: getToken(), ID: params[5] }).then(rs => rs.json()).then(res => {
            if (res.return) this.setState({_load_mont: res.data})
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
        POST_API('/operation/exportadora-filter.php', { token: getToken(), ID: params[5] }).then(rs => rs.json()).then(res => {
            if (res.return) this.setState({_load_expo: res.data})
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
    }

    componentWillUnmount = () => {
        clearInterval(this.loop)
    }

    onView = async () => {
        var params = window.location.href.split('/')
        POST_API('/operation/view_dashboard.php', { token: getToken(), ID: params[5] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({_screen: true, defaultValue: res.data})
            } else {
                message.warning({ content: _p(res.msg), key: 'screen' })
                this.setState({_navigate: true})
                clearInterval(this.loop)
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
            this.setState({_navigate: true})
        }).finally(() => this.setState({_loading: false}))
    
    }

    openModal = (id) => {
        this.setState({_modal: true, _breakdown_loading: true})
        POST_API('/operation/cargo/breakdown/search.php', { token: getToken(), filter: `{"OPERATION_ID":"${this.state._params[5]}","OPERATION_CARGO_ID":"${id}"}`, sorter: `{"columnKey":"operation_cargo_breakdown.DATETIME_INSERT","order":"descend"}` }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({ _breakdown: res.data })
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        }).finally(() => this.setState({_breakdown_loading: false}))
    }

    onSend = async (values) => {

        this.setState({_loading_form: true, _pagination: {
            current: 1,
            total: 0,
            limit: 10
        },})

        values.OPERATION_ID = this.state._params[5]
        this.setState({_filters: values})
        POST_API('/operation/cargo/search.php', { token: getToken(), pagination: JSON.stringify({ current: 1, total: 0, limit: 10 }), filter: JSON.stringify(values), search: values.SEARCH }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({_list: res.data, _pagination: { ...this.state._pagination, total: res.summary.QTDE }})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        }).finally(() => this.setState({_loading_form: false}))

    }

    onPaginationChange = (page, size) => {
        this.setState({_loading_form: true, _pagination: {...this.state._pagination, current: page, limit: size}})
        setTimeout(() => {
            POST_API('/operation/cargo/search.php', { token: getToken(), pagination: JSON.stringify(this.state._pagination), filter: JSON.stringify(this.state._filters), search: this.state._filters.SEARCH }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({_list: res.data, _pagination: { ...this.state._pagination, total: res.summary.QTDE }})
                } else {
                    message.error({ content: _p(res.msg), key: 'screen' })
                }
            }).catch(() => {
                message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
            }).finally(() => this.setState({_loading_form: false}))
        }, 500);

    }

    onReport = (id) => {

        var mapForm = document.createElement("form");
        mapForm.target = "_blank";   
        mapForm.style = "display: none"; 
        mapForm.method = "POST";
        mapForm.action = `${URL_API}/report/one.php`;

        var mapInputToken = document.createElement("input");
        mapInputToken.type = "text";
        mapInputToken.name = "token";
        mapInputToken.value = getToken();
        mapForm.appendChild(mapInputToken);

        var mapInputId = document.createElement("input");
        mapInputId.type = "text";
        mapInputId.name = "ID";
        mapInputId.value = this.state._params[5];
        mapForm.appendChild(mapInputId);

        var mapInputId2 = document.createElement("input");
        mapInputId2.type = "text";
        mapInputId2.name = "CHASSI_ID";
        mapInputId2.value = id;
        mapForm.appendChild(mapInputId2);

        var mapInputLG = document.createElement("input");
        mapInputLG.type = "text";
        mapInputLG.name = "LANGUAGE";
        mapInputLG.value = getLanguageUpper();
        mapForm.appendChild(mapInputLG);

        document.body.appendChild(mapForm);
        mapForm.submit();

    }

    onReportBrand = () => {

        var mapForm = document.createElement("form");
        mapForm.target = "_blank";   
        mapForm.style = "display: none"; 
        mapForm.method = "POST";
        mapForm.action = `${URL_API}/report/brand.php`;

        var mapInputToken = document.createElement("input");
        mapInputToken.type = "text";
        mapInputToken.name = "token";
        mapInputToken.value = getToken();
        mapForm.appendChild(mapInputToken);

        var mapInputId = document.createElement("input");
        mapInputId.type = "text";
        mapInputId.name = "ID";
        mapInputId.value = this.state._params[5];
        mapForm.appendChild(mapInputId);

        var mapInputId2 = document.createElement("input");
        mapInputId2.type = "text";
        mapInputId2.name = "ASSEMBLER_ID";
        mapInputId2.value = this.state.select;
        mapForm.appendChild(mapInputId2);

        var mapInputId2 = document.createElement("input");
        mapInputId2.type = "text";
        mapInputId2.name = "PFD_ID";
        mapInputId2.value = this.state.pfd;
        mapForm.appendChild(mapInputId2);

        var mapInputLG = document.createElement("input");
        mapInputLG.type = "text";
        mapInputLG.name = "LANGUAGE";
        mapInputLG.value = getLanguageUpper();
        mapForm.appendChild(mapInputLG);

        document.body.appendChild(mapForm);
        mapForm.submit();

    }

    render = () => {

        const { Content } = Layout

        if (getToken() == null) {
            return (<Page403 />)
        }

        if (this.state._navigate) {
            return (<Page500 />)
        }

        return (
            <Content className="sys-container">
                <Tabs centered>
                    <Tabs.TabPane tab={_t("Estatísticas")} key="item-1">
                        <Row gutter={[8,8]} justify="center">
                            <Col span={24}>
                                <Typography className="sys-title">- {_t('Informações Gerais')} -</Typography>
                            </Col>
                            {this.state._loading ? <Spin/> : (
                                <Col xs={24}>
                                    <Row gutter={[8,8]}>
                                        <Col xs={24} md={12}>
                                            <Card size="small" hoverable className="sys-card-operation" title={`#${this.state.defaultValue.ID} ${this.state.defaultValue.SHIP_NAME} - ${this.state.defaultValue.PFD_NAME}`}>
                                                <Row gutter={[16,16]} style={{alignItems: 'center'}}>
                                                    <Col>
                                                        <Progress width={100} type="circle" percent={this.state.defaultValue.PERCENT !== 'nan' ? this.state.defaultValue.PERCENT : 0} strokeColor={this.state.defaultValue.STATUS_COLOR} />
                                                    </Col>
                                                    <Col>
                                                        <Typography><span className="sys-card-operation-title">{_t('Situação')}:</span> {this.state.defaultValue[`STATUS_NAME_${getLanguageUpper()}`]}</Typography>
                                                        <Typography><span className="sys-card-operation-title">{_t('Armador')}:</span> {this.state.defaultValue.SHIPOWNER_NAME}</Typography>
                                                        <Typography><span className="sys-card-operation-title">{_t('Total Chassis')}:</span> {this.state.defaultValue.TOTAL_CHASSIS}</Typography>
                                                        <Typography><span className="sys-card-operation-title">{_t('Total Coletados')}:</span> {this.state.defaultValue.TOTAL_COLETADOS}</Typography>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                        <Col span={24}>
                                            <Typography className="sys-title">- {_t('Por tipo de operação')} -</Typography>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Card size="small" hoverable className="sys-card-operation" title={_t(`Importação`)}>
                                                <Row gutter={[16,16]} style={{alignItems: 'center'}}>
                                                    <Col>
                                                        <Progress width={100} type="circle" percent={this.state.defaultValue.PERCENT_IMPORT !== 'nan' ? this.state.defaultValue.PERCENT_IMPORT : 0} strokeColor={this.state.defaultValue.STATUS_COLOR} />
                                                    </Col>
                                                    <Col>
                                                        <Typography><span className="sys-card-operation-title">{_t('Total Chassis')}:</span> {this.state.defaultValue.TOTAL_IMPORT_CHASSIS}</Typography>
                                                        <Typography><span className="sys-card-operation-title">{_t('Total Coletados')}:</span> {this.state.defaultValue.TOTAL_IMPORT_COLETADOS}</Typography>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Card size="small" hoverable className="sys-card-operation" title={_t(`Exportação`)}>
                                                <Row gutter={[16,16]} style={{alignItems: 'center'}}>
                                                    <Col>
                                                        <Progress width={100} type="circle" percent={this.state.defaultValue.PERCENT_EXPORT !== 'nan' ? this.state.defaultValue.PERCENT_EXPORT : 0} strokeColor={this.state.defaultValue.STATUS_COLOR} />
                                                    </Col>
                                                    <Col>
                                                        <Typography><span className="sys-card-operation-title">{_t('Total Chassis')}:</span> {this.state.defaultValue.TOTAL_EXPORT_CHASSIS}</Typography>
                                                        <Typography><span className="sys-card-operation-title">{_t('Total Coletados')}:</span> {this.state.defaultValue.TOTAL_EXPORT_COLETADOS}</Typography>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                        <Col span={24}>
                                            <Typography className="sys-title">- {_t('Por Porto')} -</Typography>
                                        </Col>
                                        {this.state.defaultValue.PORTS.length > 0 ? (
                                            <>
                                                {this.state.defaultValue.PORTS.map((v,i) => (
                                                    <Col xs={24} md={12}>
                                                        <Card size="small" hoverable className="sys-card-operation" title={`${v.POL_NAME} > ${v.POD_NAME} > ${v.PFD_NAME}`}>
                                                            <Row gutter={[16,16]} style={{alignItems: 'center'}}>
                                                                <Col>
                                                                    <Progress width={100} type="circle" percent={v.PERCENT !== 'nan' ? v.PERCENT : 0} strokeColor={v.POD_COLOR} />
                                                                </Col>
                                                                <Col>
                                                                    <Typography><span className="sys-card-operation-title">{_t('Tipo de Operação')}:</span> {v[`TYPE_NAME_${getLanguageUpper()}`]}</Typography>
                                                                    <Typography><span className="sys-card-operation-title">{_t('Tipo de Carga')}:</span> {v[`CATEGORY_NAME_${getLanguageUpper()}`]}</Typography>
                                                                    <Typography><span className="sys-card-operation-title">{_t('Mont./Export.')}:</span> {v.ASSEMBLER_NAME} / {v.EXPORT_NAME}</Typography>
                                                                    <Typography><span className="sys-card-operation-title">{_t('Total Chassis')}:</span> {v.TOTAL_CHASSIS}</Typography>
                                                                    <Typography><span className="sys-card-operation-title">{_t('Total Coletados')}:</span> {v.TOTAL_COLETADOS}</Typography>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                ))}
                                            </>
                                        ) : <Col span={24}><Typography style={{textAlign: 'center'}}>Sem portos cadastrados</Typography></Col> }
                                    </Row>
                                </Col>
                            )}
                        </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={_t("Lista de Chassi")} key="item-2">
                        <Row gutter={[8,8]}>
                            <Col span={24}>
                                <Form ref={this.FormRef} className="sys-form" onFinish={this.onSend}>
                                    <Row gutter={[8,8]} align="bottom">
                                        <Col span={24}>
                                            <Typography className="sys-title">- {_t('Filtros')} -</Typography>
                                        </Col>
                                        <Col xs={24} md={4}>
                                            <Form.Item initialValue={'*'} name="TYPE" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                                <TextField size="small" select fullWidth variant="standard" label={_t('Tipo de Operação')} type='text'>
                                                    <MenuItem value="*">{_t('Todos')}</MenuItem>
                                                    <MenuItem value="E">{_t('Exportação')}</MenuItem>
                                                    <MenuItem value="I">{_t('Importação')}</MenuItem>
                                                </TextField>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={4}>
                                            <Form.Item initialValue={'*'} name="POL_ID" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                                <TextField size="small" select fullWidth variant="standard" label={_t('POL')} type='text'>
                                                    <MenuItem value="*">{_t('Todos')}</MenuItem>
                                                    {this.state._load_port.map((v,i) => (
                                                        <MenuItem value={v.ID} k={i}>{v.COD}</MenuItem>
                                                    ))}
                                                </TextField>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={4}>
                                            <Form.Item initialValue={'*'} name="POD_ID" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                                <TextField size="small" select fullWidth variant="standard" label={_t('POD')} type='text'>
                                                    <MenuItem value="*">{_t('Todos')}</MenuItem>
                                                    {this.state._load_port.map((v,i) => (
                                                        <MenuItem value={v.ID} k={i}>{v.COD}</MenuItem>
                                                    ))}
                                                </TextField>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={4}>
                                            <Form.Item initialValue={'*'} name="PFD_ID" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                                <TextField size="small" select fullWidth variant="standard" label={_t('PFD (*)')} type='text' onChange={(v) => this.setState({pfd: v.target.value})}>
                                                    <MenuItem value="*">{_t('Todos')}</MenuItem>
                                                    {this.state._load_port.map((v,i) => (
                                                        <MenuItem value={v.ID} k={i}>{v.COD}</MenuItem>
                                                    ))}
                                                </TextField>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={4}>
                                            <Form.Item initialValue={'*'} name="ASSEMBLER_ID" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                                <TextField size="small" select fullWidth variant="standard" label={_t('Montadora (*)')} type='text' onChange={(v) => this.setState({select: v.target.value})}>
                                                    <MenuItem value="*">{_t('Todos')}</MenuItem>
                                                    {this.state._load_mont.map((v,i) => (
                                                        <MenuItem value={v.ASSEMBLER_ID} k={i}>{v.ASSEMBLER_NAME}</MenuItem>
                                                    ))}
                                                </TextField>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={4}>
                                            <Form.Item initialValue={'*'} name="EXPORT_ID" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                                <TextField size="small" select fullWidth variant="standard" label={_t('Exportadora')} type='text'>
                                                    <MenuItem value="*">{_t('Todos')}</MenuItem>
                                                    {this.state._load_expo.map((v,i) => (
                                                        <MenuItem value={v.EXPORT_ID} k={i}>{v.EXPORT_NAME}</MenuItem>
                                                    ))}
                                                </TextField>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <Form.Item initialValue={'*'} name="STATUS" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                                <TextField size="small" select fullWidth variant="standard" label={_t('Coletado')} type='text'>
                                                    <MenuItem value="*">{_t('Todos')}</MenuItem>
                                                    <MenuItem value="S">{_t('Sim')}</MenuItem>
                                                    <MenuItem value="N">{_t('Não')}</MenuItem>
                                                </TextField>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <Form.Item initialValue={'*'} name="DAMAGE" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                                <TextField size="small" select fullWidth variant="standard" label={_t('Danificado')} type='text'>
                                                    <MenuItem value="*">{_t('Todos')}</MenuItem>
                                                    <MenuItem value="S">{_t('Sim')}</MenuItem>
                                                    <MenuItem value="N">{_t('Não')}</MenuItem>
                                                </TextField>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={10}>
                                            <Form.Item initialValue={''} name="SEARCH">
                                                <TextField size="small" fullWidth variant="standard" label={_t('Pesquisar...')} type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={3}>
                                            <Form.Item>
                                                <Button loading={this.state._loading_form} htmlType="submit" block type="default" className="sys-btn-default">{_t('Procurar')}</Button>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={3}>
                                            <Form.Item>
                                                <Button onClick={this.onReportBrand} disabled={(this.state.select == '*')} block type="default" className="sys-btn-default">{_t('Relatório')}</Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                            <Col span={24}>
                                <Typography className="sys-title">- {_t('Lista')} -</Typography>
                            </Col>
                            {this.state._loading_form ? <Col span={24}><Row justify="center"><Spin /></Row></Col> : (
                                <>
                                    {this.state._list.length > 0 ? (
                                        <>
                                        {this.state._list.map((v,i) => (
                                            <Col xs={24} md={12} key={i}>
                                                <Card size="small" hoverable className="sys-card-operation" title={`VIN #${v.CHASSI}`} actions={[
                                                    <Button disabled={v.STATUS === 'S' ? false : true} onClick={() => this.onReport(v.ID)} title={_t("Gerar Relatório")} size='small' shape='circle' type='text'><IoArchiveOutline size={18}/></Button>,
                                                    <Button disabled={v.QTDE_DANO > 0 ? false : true} onClick={() => this.openModal(v.ID)} title={_t("Ver Avarias")} size='small' shape='circle' type='text'><IoConstructOutline size={18}/></Button>,
                                                ]}>
                                                    <Typography><span className="sys-card-operation-title">{_t('Tipo de Operação')}:</span> {_t(v[`TYPE_NAME_${getLanguageUpper()}`])}</Typography>
                                                    <Typography><span className="sys-card-operation-title">{v[`FIELD_NAME_${getLanguageUpper()}`]}:</span> {v.FIELD_CONTROL}</Typography>
                                                    <Typography><span className="sys-card-operation-title">{_t('Categoria')}:</span> {v[`CATEGORY_NAME_${getLanguageUpper()}`]}</Typography>
                                                    <Typography><span className="sys-card-operation-title">{_t('Mont./Export.')}:</span> {v.ASSEMBLER_NAME}/{v.EXPORT_NAME}</Typography>
                                                    <Typography><span className="sys-card-operation-title">{_t('Rota')}:</span> {v.POL_NAME} {'>'} {v.POD_NAME}</Typography>
                                                    <Typography><span className="sys-card-operation-title">{_t('Danificado')}:</span> {v.QTDE_DANO > 0 ? _t('Sim') : _t('Não')}</Typography>
                                                    <Tag style={{width: '100%', textAlign: 'center'}} color={v.STATUS_COLOR}>{_t(v.STATUS_NAME)}</Tag>
                                                </Card>
                                            </Col>
                                        ))}
                                        <Col span={24}>
                                            <Row justify="end">
                                                <Pagination current={this.state._pagination.current} defaultCurrent={this.state._pagination.current} total={this.state._pagination.total} onChange={this.onPaginationChange} showSizeChanger={false}/>
                                            </Row>
                                        </Col>
                                        </>
                                    ) : <Col span={24}><Typography style={{textAlign: 'center'}}>{_t('Nenhum chassi encontrado')}</Typography></Col> }
                                </>
                            )}
                            <Modal style={{ top: 20 }} title={_t("Ver Avarias")} open={this.state._modal} onCancel={() => this.setState({_modal: false})} footer={false}>
                                {this.state._breakdown_loading ? <Row justify="center"><Spin /></Row> : (
                                    <>
                                    {this.state._breakdown.length > 0 ? (
                                        <Col span={24}>
                                            <Row gutter={[8,8]}>
                                                {this.state._breakdown.map((v,i) => (
                                                    <Col span={24} key={i}>
                                                        <Card size="small" hoverable className="sys-card-operation">
                                                            <Row gutter={[4,4]} style={{alignItems: 'center'}}>
                                                                <Col span={24}><Typography><span className="sys-card-operation-title">{_t('Parte')}:</span> {v[`PART_NAME_${getLanguageUpper()}`]}</Typography></Col>
                                                                <Col span={24}><Typography><span className="sys-card-operation-title">{_t('Avaria')}:</span> {v[`BREAKDOWN_NAME_${getLanguageUpper()}`]}</Typography></Col>
                                                                <Col span={24}><Typography><span className="sys-card-operation-title">{_t('Severidade')}:</span> {v[`SEVERITY_NAME_${getLanguageUpper()}`]}</Typography></Col>
                                                                {v.PHOTOS.length > 0 ? (
                                                                    <Col span={24}>
                                                                        <Carousel autoplay>
                                                                            {v.PHOTOS.map((v2,i2) => (
                                                                                <div>
                                                                                    <Image src={v2.URL} width={'100%'} height={200} style={{objectFit: 'contain'}} />
                                                                                </div>
                                                                            ))}
                                                                        </Carousel>
                                                                    </Col>
                                                                ) : null }
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Col>
                                    ) : (
                                        <Col span={24}>
                                            <Typography style={{textAlign: 'center'}}>- {_t('Nenhuma avaria encontrada')} -</Typography>
                                        </Col>
                                    )}
                                    </>
                                )}
                            </Modal>
                        </Row>
                    </Tabs.TabPane>
                </Tabs>
            </Content>
        )
    }

}

export default Screen;