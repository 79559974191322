import React from "react";
import { Breadcrumb, Button, Col, Form, Image, Input, Layout, message, Row, Typography, Upload } from 'antd';
import { Link, Navigate } from "react-router-dom";
import { TextField } from "@mui/material";

// API
import { getToken, POST_API, UPLOAD_API, verifyConfig } from "../../../services/server-api";

// TRANSLATE
import { _p, _t } from "../../../services/language";

// CONTROLLER
import Page403 from '../../Controller/Page403';
import PageLoad from '../../Controller/PageLoad';

// PERMISSIONS
import { _EDIT } from "./_permission";

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        _screen: false,
        defaultValue: {
            ID: 0,
            NAME: '',
            COLOR_PRIMARY: '#000000',
            COLOR_SECONDARY: '#000000',
        }
    }

    componentDidMount = () => {
        this.onView()
    }

    onView = () => {
        var params = window.location.href.split('/')
        message.loading({ content: _t('Carregando...'), key: 'screen' })
        POST_API('/enviroment/view.php', { token: getToken(), ID: params[5] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: _p(res.msg), key: 'screen' })
                this.setState({_screen: true, defaultValue: res.data})
            } else {
                message.warning({ content: _p(res.msg), key: 'screen' })
                this.setState({_navigate: true})
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
            this.setState({_navigate: true})
        }).finally(() => this.setState({_loading: false}))
    }

    onSend = (values) => {
        this.setState({_loading: true})
        POST_API('/enviroment/save.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: _p(res.msg), key: 'send' })
                this.onView()
            } else {
                message.warning({ content: _p(res.msg), key: 'send' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'send' })
        }).finally(() => this.setState({_loading: false}))
    }

    render = () => {

        const { Content } = Layout

        if (this.state._navigate) {
            return (<Navigate to={'/dashboard/enviroment/'} />)
        }

        if (getToken() == null  || !verifyConfig(_EDIT)) {
            return (<Page403 />)
        }

        return (
            <Content className="sys-container">
                <Row gutter={[8,8]}>
                    <Col span={20}>
                        <Breadcrumb className="sys-breadcrumb">
                            <Breadcrumb.Item><Link to="/dashboard/enviroment">{_t('Ambientes')}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>{_t('Atualizar')}</Breadcrumb.Item>
                            <Breadcrumb.Item>{this.state.defaultValue.ID}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={4}>
                        <Link to="/dashboard/enviroment"><Button type="primary" className="sys-btn-primary sys-btn-small float-right">{_t('Voltar')}</Button></Link>
                    </Col>
                    <Col span={24}>
                    {this.state._screen ? (
                        <Form layout="vertical" className="sys-form" onFinish={this.onSend} initialValues={this.state.defaultValue} defaultValue={this.state.defaultValue}>
                            <Form.Item hidden name="ID">
                                <Input  />
                            </Form.Item>
                            <Row gutter={[8,0]}>
                                <Col xs={24} md={16}>
                                    <Form.Item name="NAME" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                        <TextField fullWidth variant="standard" label={_t("Nome")} type='text' />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} md={4}>
                                    <Form.Item name="COLOR_PRIMARY" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                        <TextField fullWidth variant="standard" label={_t("Cor Primária")} type='color' />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} md={4}>
                                    <Form.Item name="COLOR_SECONDARY" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                        <TextField fullWidth variant="standard" label={_t("Cor Secundária")} type='color' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item name="FOOTER">
                                        <TextField multiline fullWidth variant="standard" label={_t("Rodapé")} type='text' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item name="LOGO_LINK" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                        <Upload action={UPLOAD_API} maxCount={1} className="sys-ant-upload">
                                            <Button block type="primary" className="sys-btn-primary">{_t('Selecionar Favicon')}</Button>
                                        </Upload>
                                    </Form.Item>
                                    <Typography className="sys-label-txt">{_t('Favicon')}</Typography>
                                    <Image preview={false} src={this.state.defaultValue.LOGO_LINK} />
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item name="LOGO_LOGIN" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                        <Upload action={UPLOAD_API} maxCount={1} className="sys-ant-upload">
                                            <Button block type="primary" className="sys-btn-primary">{_t('Selecionar Imagem Login')}</Button>
                                        </Upload>
                                    </Form.Item>
                                    <Typography className="sys-label-txt">{_t('Imagem Login')}</Typography>
                                    <Image preview={false} src={this.state.defaultValue.LOGO_LOGIN} />
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item name="LOGO_NAVBAR" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                        <Upload action={UPLOAD_API} maxCount={1} className="sys-ant-upload">
                                            <Button block type="primary" className="sys-btn-primary">{_t('Selecionar Imagem Cabeçalho')}</Button>
                                        </Upload>
                                    </Form.Item>
                                    <Typography className="sys-label-txt">{_t('Imagem Cabeçalho')}</Typography>
                                    <Image preview={false} src={this.state.defaultValue.LOGO_NAVBAR} />
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item name="LOGO_SIDEBAR" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                        <Upload action={UPLOAD_API} maxCount={1} className="sys-ant-upload">
                                            <Button block type="primary" className="sys-btn-primary">{_t('Selecionar Imagem Menu')}</Button>
                                        </Upload>
                                    </Form.Item>
                                    <Typography className="sys-label-txt">{_t('Imagem Menu')}</Typography>
                                    <Image preview={false} src={this.state.defaultValue.LOGO_SIDEBAR} />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Button loading={this.state._loading} htmlType="submit" type="default" className="sys-btn-default float-right">{_t('Salvar')}</Button>
                                </Col>
                            </Row>
                        </Form>
                    ) : <PageLoad /> }
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;