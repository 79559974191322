import React from "react";
import { Button, Card, Col, Layout, message, Progress, Row, Spin, Typography } from 'antd';
import { Link } from "react-router-dom";

// API
import { getToken, POST_API, URL_API } from "../../../services/server-api";

// TRANSLATE
import { getLanguageUpper, _p, _t } from "../../../services/language";

// CONTROLLER
import Page403 from '../../Controller/Page403';
import { IoArchiveOutline, IoClipboardOutline, IoTimeOutline } from "react-icons/io5";

class Screen extends React.Component {

    state = {
        params: window.location.href.split('/'),
        path: null,
        load: true,
        operations: []
    }

    loop = null;
    loopa = null;

    componentDidMount = () => {
        this.onLoadPath()
        this.onRenderData().then(() => {
            this.loopa = setInterval(() => {
                if (window.location.href.split('/')[5] != this.state.params[5]) {
                    this.setState({ path: null, load: true, params: window.location.href.split('/')  })
                    POST_API('/path/view.php', { token: getToken(), ID: window.location.href.split('/')[5] }).then(rs => rs.json()).then(res => {
                        if (res.return) {
                            this.setState({ path: res.data })
                        } else {
                            message.error({ content: _p(res.msg), key: 'screen' })
                        }
                    }).catch(() => {
                        message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
                    }).finally(); 
                }
            }, 1000)
            this.loop = setInterval(() => {
                this.onRenderData()
            }, 10000);
        })
    }

    componentWillUnmount = () => {
        clearInterval(this.loop)
        clearInterval(this.loopa)
    }

    onLoadPath = async () => {
        POST_API('/path/view.php', { token: getToken(), ID: this.state.params[5] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({ path: res.data })
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        }).finally(); 
    }

    onRenderData = async () => {
        POST_API('/operation/search.php', { token: getToken(), filter: '{"FINALIZED":"N", "PATH_ID": '+this.state.params[5]+'}' }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({operations: res.data })
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        }).finally(() => this.setState({load: false})); 
    }

    onReport = (id) => {

        var mapForm = document.createElement("form");
        mapForm.target = "_blank";   
        mapForm.style = "display: none"; 
        mapForm.method = "POST";
        mapForm.action = `${URL_API}/report/all.php`;

        var mapInputToken = document.createElement("input");
        mapInputToken.type = "text";
        mapInputToken.name = "token";
        mapInputToken.value = getToken();
        mapForm.appendChild(mapInputToken);

        var mapInputId = document.createElement("input");
        mapInputId.type = "text";
        mapInputId.name = "ID";
        mapInputId.value = id;
        mapForm.appendChild(mapInputId);

        var mapInputLG = document.createElement("input");
        mapInputLG.type = "text";
        mapInputLG.name = "LANGUAGE";
        mapInputLG.value = getLanguageUpper();
        mapForm.appendChild(mapInputLG);

        document.body.appendChild(mapForm);
        mapForm.submit();

    }

    render = () => {

        const { Content } = Layout

        if (getToken() == null) {
            return (<Page403 />)
        }

        return (
            <Content className="sys-container">
                <Row gutter={[8,8]} justify="center">
                    <Col span={24}>
                        <Typography className="sys-title">- {_t('Operações em andamento')} - {this.state.path?.NAME} -</Typography>
                    </Col>
                    {this.state.load ? <Spin/> : (
                        <Col span={24}>
                            {this.state.operations.length > 0 ? (
                                <Row gutter={[8,8]}>
                                    {this.state.operations.map((v,i) => (
                                        <Col xs={24} md={12} key={i}>
                                            <Card size="small" hoverable className="sys-card-operation" title={`${v.COD} - ${v.SHIP_NAME} - ${v.PFD_NAME}`} actions={[
                                                <Button onClick={() => this.onReport(v.ID)} title={_t("Gerar Relatório")} size='small' shape='circle' type='text'><IoArchiveOutline size={18}/></Button>,
                                                <Link to={'/dashboard/inspector/'+v.ID}><Button title={_t("Realizar Inspeção")} size='small' shape='circle' type='text'><IoClipboardOutline size={18}/></Button></Link>,
                                                <Link to={'/dashboard/inspector/'+v.ID+'/realtime'}><Button title={_t("Acompanhar Inspeção")} size='small' shape='circle' type='text'><IoTimeOutline size={18}/></Button></Link>,
                                            ]}>
                                                <Row gutter={[16,16]} style={{alignItems: 'center'}}>
                                                    <Col>
                                                        <Progress width={100} type="circle" percent={v.PERCENT !== 'nan' ? v.PERCENT : 0} strokeColor={v.STATUS_COLOR} />
                                                    </Col>
                                                    <Col>
                                                        <Typography><span className="sys-card-operation-title">{_t('Situação')}:</span> {v[`STATUS_NAME_${getLanguageUpper()}`]}</Typography>
                                                        <Typography><span className="sys-card-operation-title">{_t('Armador')}:</span> {v.SHIPOWNER_NAME}</Typography>
                                                        <Typography><span className="sys-card-operation-title">{_t('Total Chassis')}:</span> {v.TOTAL_CHASSIS}</Typography>
                                                        <Typography><span className="sys-card-operation-title">{_t('Total Coletados')}:</span> {v.TOTAL_COLETADOS}</Typography>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            ) : (
                                <Col span={24} style={{textAlign: 'center'}}>Nenhuma operação encontrada</Col>
                            )}
                        </Col>
                    )}
                </Row>
            </Content>
        )
    }

}

export default Screen;