import React from "react";
import HomePathAdmin from "./HomePathAdmin";
import HomePathInspection from "./HomePathInspection";
import HomePathClient from "./HomePathClient";

// SERVICES
import { verifyConfig } from "../../../services/server-api";

class Screen extends React.Component {

    render = () => {

        if (verifyConfig('EVaUY1MfNVIAhdncgbD/C2AdKkosA1iGsyHfP7GyJaQ=')) return ( <></> );

        if (verifyConfig('a99odWPFRuy8oDT9XYSpjC5mFQw2QmEqSEzFpTPD8gs=')) return ( <HomePathAdmin /> );
        if (verifyConfig('a99odWPFRuy8oDT9XYSpjAimwE/DN2oci7TqpPMRV9Y=')) return ( <HomePathInspection /> );

        return ( <HomePathClient /> )

    }        

}

export default Screen;