import React from "react";
import { Col, Form, Row, Typography, Pagination, Table } from "antd";
import { ThemeProvider } from '@mui/material/styles';
import { TextField } from "@mui/material";

// THEMES
import { principal } from '../../themes';
import { _t } from "../../services/language";

class Component extends React.Component {

    render = () => {

        const { _columns, _data, _onChangeTable, _onSend, _loading, _onChangePagination, _pagination, _onChangeSearch, _search } = this.props

        return (
            <Row className="table__row_table" gutter={[8,8]}>
                <Col span={24}>
                    <ThemeProvider theme={principal}>
                        <Row>
                            <Col span={12} style={{display: 'flex', alignItems: 'center'}}>
                                <Typography className="table__summary">{_t('Total de')} {_pagination.total} {_t('registros retornados')}</Typography>
                            </Col>
                            <Col span={12}>
                                <Form layout="vertical" onFieldsChange={_onSend} className="table__form_filter">
                                    <Form.Item name="search">
                                        <TextField size="small" onChange={_onChangeSearch} placeholder={_t("Pesquisar...")} variant="standard" className="table__form_filter_input" value={_search} />
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </ThemeProvider>
                </Col>
                <Col span={24}>
                    <Table
                        pagination={false}
                        onChange={_onChangeTable}
                        columns={_columns}
                        dataSource={_data} 
                        loading={_loading}
                        scroll={{x: true}}
                        className='table__'
                    />
                </Col>
                {_pagination?.current > 0 ? (
                    <Col span={24}>
                        <Row justify="end">
                            <Pagination current={_pagination.current} defaultCurrent={_pagination.current} total={_pagination.total} onChange={_onChangePagination} showSizeChanger/>
                        </Row>
                    </Col>
                ) : null }
            </Row>
        )
    }
    
}

export default Component;