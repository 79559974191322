import React from "react";
import { Button, Card, Col, Divider, Form, Layout, message, Modal, Progress, Row, Select, Spin, Typography } from 'antd';
import { Link, Navigate } from "react-router-dom";

// API
import { getToken, POST_API } from "../../../services/server-api";

// TRANSLATE
import { getLanguageUpper, _p, _t } from "../../../services/language";

// CONTROLLER
import Page403 from '../../Controller/Page403';
import { IoArchiveOutline, IoClipboardOutline } from "react-icons/io5";
import { Chip, MenuItem, TextField } from "@mui/material";

class Screen extends React.Component {

    state = {
        _navigate: false,
        _params: window.location.href.split('/'),
        _loading: true,
        _screen: false,
        _modal: false,
        defaultValue: {
            ID: 0
        },
        formvalues: {},
        _list_local_reading: [],
        _list_type_reading: [],
        _list_sub_reading: [],
        _list_type_cargo: [],
        _list_step: [],
        TYPE_READING_ID: '*',
        LOCAL_READING_ID: '*'
    }

    componentDidMount = () => {
        this.onView()
        this.loadOption()
    }

    loadOption = async () => {
        await POST_API('/typereading/filter.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({_list_type_reading: res.data})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
        await POST_API('/localreading/filter.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({_list_local_reading: res.data})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
        await POST_API('/typecargo/filter.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({_list_type_cargo: res.data})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
        await POST_API('/operation/step/filter.php', { token: getToken(), OPERATION_ID: this.state._params[5] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({_list_step: res.data})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
    }

    loadSub = async (value, type) => {

        var TYPE_READING_ID = (type == 'TYPE_READING_ID') ? value : this.state.TYPE_READING_ID;
        var LOCAL_READING_ID = (type == 'LOCAL_READING_ID') ? value : this.state.LOCAL_READING_ID;

        await POST_API('/subtypereading/filter.php', { token: getToken(), TYPE_READING_ID, LOCAL_READING_ID }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({_list_sub_reading: res.data})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
    }

    onView = () => {
        var params = window.location.href.split('/')
        POST_API('/operation/view.php', { token: getToken(), ID: params[5] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: _p(res.msg), key: 'screen' })
                this.setState({_screen: true, defaultValue: res.data})
            } else {
                message.warning({ content: _p(res.msg), key: 'screen' })
                this.setState({_navigate: true})
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
            this.setState({_navigate: true})
        }).finally(() => this.setState({_loading: false}))
    }

    openModal = () => {
        this.setState({_modal: !this.state._modal})
    }

    onSend = async (values) => {
        this.setState({formvalues: values, _navigate: true})
    }

    render = () => {

        const { Content } = Layout

        if (this.state._navigate) {
            return (<Navigate state={this.state.formvalues} to={`collect/${this.state.formvalues.LOCAL_READING_ID}/${this.state.formvalues.TYPE_READING_ID}/${this.state.formvalues.SUBTYPE_READING_ID}/${this.state.formvalues.TYPE_CARGO_ID}`} />)
        }

        if (getToken() == null) {
            return (<Page403 />)
        }

        return (
            <Content className="sys-container">
                <Row gutter={[8,8]} justify="center">
                    <Col span={24}>
                        <Typography className="sys-title">- {_t('Operação em andamento')} -</Typography>
                    </Col>
                    {this.state._loading ? <Spin/> : (
                        <Col xs={24}>
                            <Row gutter={[8,8]}>
                                <Col span={24}>
                                    <Card size="small" hoverable className="sys-card-operation" title={`#${this.state.defaultValue.ID} ${this.state.defaultValue.SHIP_NAME} - ${this.state.defaultValue.PFD_NAME}`}>
                                        <Row gutter={[16,16]} style={{alignItems: 'center'}}>
                                            <Col>
                                                <Progress width={100} type="circle" percent={this.state.defaultValue.PERCENT} strokeColor={this.state.defaultValue.STATUS_COLOR} />
                                            </Col>
                                            <Col>
                                                <Typography><span className="sys-card-operation-title">{_t('Situação')}:</span> {this.state.defaultValue[`STATUS_NAME_${getLanguageUpper()}`]}</Typography>
                                                <Typography><span className="sys-card-operation-title">{_t('Armador')}:</span> {this.state.defaultValue.SHIPOWNER_NAME}</Typography>
                                                <Typography><span className="sys-card-operation-title">{_t('Total Chassis')}:</span> {this.state.defaultValue.TOTAL_CHASSIS}</Typography>
                                                <Typography><span className="sys-card-operation-title">{_t('Total Coletados')}:</span> {this.state.defaultValue.TOTAL_COLETADOS}</Typography>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col span={24}>
                                    <Button onClick={this.openModal} type="primary" shape="round" block className="sys-btn-primary">{_t('Coletar Chassi')}</Button>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Divider><Chip label={_t('Detalhes')}/></Divider>
                                </Col>
                                <Col span={24}>
                                    <Link to={`detail`}><Button type="primary" shape="round" block className="sys-btn-primary">{_t('Detalhes da Operação')}</Button></Link>
                                </Col>
                                <Col span={24}>
                                    <Link to={`datehour`}><Button type="primary" shape="round" block className="sys-btn-primary">{_t('Detalhes de Data/Hora')}</Button></Link>
                                </Col>
                                {this.state._list_step.length > 0 ? (
                                    <>
                                        <Col xs={24} md={24}>
                                            <Divider><Chip label={_t('Outras Etapas')}/></Divider>
                                        </Col>
                                        {this.state._list_step.map((v, i) => (
                                            <Col span={24} key={i}>
                                                <Link to={`step/${v.STEP_ID}`}><Button type="primary" shape="round" block className="sys-btn-primary">{v[`STEP_NAME_${getLanguageUpper()}`]}</Button></Link>
                                            </Col>
                                        ))}
                                    </>

                                ) : null }
                            </Row>
                        </Col>
                    )}
                    <Col span={24}>
                        <Modal closable={false} open={this.state._modal} onCancel={this.openModal} title={_t('Coletar Chassi')} footer={false}>
                            <Form layout="vertical" onFinish={this.onSend}>
                                <Row gutter={[8,8]}>
                                    <Col span={24}>
                                        <Form.Item name="LOCAL_READING_ID" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                            <TextField onChange={(v) => this.loadSub(v.target.value, 'LOCAL_READING_ID')} label={_t('Local de Leitura')} fullWidth select variant="standard">
                                                { this.state._list_local_reading.map((v,i) => (
                                                    <MenuItem value={v.ID} key={i}>{v['NAME_'+getLanguageUpper()]}</MenuItem>
                                                )) }
                                            </TextField>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item name="TYPE_READING_ID" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                            <TextField onChange={(v) => this.loadSub(v.target.value, 'TYPE_READING_ID')} label={_t('Tipo de Leitura')} fullWidth select variant="standard">
                                                { this.state._list_type_reading.map((v,i) => (
                                                    <MenuItem value={v.ID} key={i}>{v['NAME_'+getLanguageUpper()]}</MenuItem>
                                                )) }
                                            </TextField>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item name="SUBTYPE_READING_ID" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                            <TextField label={_t('Subtipo de Leitura')} fullWidth select variant="standard">
                                                { this.state._list_sub_reading.map((v,i) => (
                                                    <MenuItem value={v.ID} key={i}>{v['NAME_'+getLanguageUpper()]}</MenuItem>
                                                )) }
                                            </TextField>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item name="TYPE_CARGO_ID" rules={[{required: true, message: _t('Campo obrigatório*')}]}>
                                            <TextField label={_t('Tipo de Carga')} fullWidth select variant="standard">
                                                { this.state._list_type_cargo.map((v,i) => (
                                                    <MenuItem value={v.ID} key={i}>{v['NAME_'+getLanguageUpper()]}</MenuItem>
                                                )) }
                                            </TextField>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Button onClick={this.openModal} type="default" className="sys-btn-default"  block>{_t('Fechar')}</Button>
                                    </Col>
                                    <Col span={16}>
                                        <Button htmlType="submit" type="primary" className="sys-btn-primary" block>{_t('Iniciar Coleta')}</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal>
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;