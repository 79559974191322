import React from "react";
import { Breadcrumb, Button, Col, Layout, message, Modal, Row } from 'antd';
import { Link, Navigate } from "react-router-dom";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { IoReturnUpBackOutline } from "react-icons/io5";

// API
import { getToken, POST_API, verifyConfig } from "../../../services/server-api";

// TRANSLATE
import { getLanguageUpper, _p, _t } from "../../../services/language";

// COMPONENTS
import Table from '../../../components/Widgets/Table';

// CONTROLLER
import Page403 from '../../Controller/Page403';

// PERMISSIONS
import { _RECOVER, _TRASH } from "./_permission";

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        // TABLE
        table__load: true,
        table__column: [],
        table__data: [],
        table__search: '',
        table__pagination: {
            current: 1,
            total: 0,
            limit: 10
        },
        table__filter: null,
        table__sorter: null,
    }

    componentDidMount = () => {
        this.onLoadFilterTypeUser().then(() => this.onRenderColumns().then(() => this.onRenderData()))
    }

    onRecover = (ID) => {
        var self = this
        Modal.confirm({
            title: _t('Recuperar registro?'),
            icon: <ExclamationCircleOutlined />,
            cancelText: _t('Não'),
            okText: _t('Sim'),
            className: 'sys-modal-confirm',
            onOk() {
                POST_API('/operation/recover.php', { token: getToken(), ID: ID }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: _p(res.msg), key: 'screen' })
                        self.onRenderData()
                    } else {
                        message.warning({ content: _p(res.msg), key: 'screen' })
                    }
                }).catch(() => {
                    message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
                }).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
    }

    onLoadFilterTypeUser = async () => {
        await POST_API('/typeuser/filter.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({filter__type_user: res.data})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
    }

    // FUNCTION TABLE
    onRenderColumns = async () => {
        this.onLoadFilterTypeUser().then(() => {
            this.setState({
                table__column: [
                    { title: 'Cod.', dataIndex: 'ID', key: 'severity.ID', width: 100, align: 'center', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                    { title: _t('Código'), dataIndex: `COD`, key: `severity.COD`, sorter: { compare: (a,b) => null }, showSorterTooltip: false  },
                    { title: _t('Situação'), dataIndex: `STATUS_NAME_${getLanguageUpper()}`, key: `status.NAME_${getLanguageUpper()}`, sorter: { compare: (a,b) => null }, showSorterTooltip: false  },
                    { title: _t('Embarcação'), dataIndex: `SHIP_NAME`, key: `ship.NAME`, sorter: { compare: (a,b) => null }, showSorterTooltip: false  },
                    { title: _t('Armador'), dataIndex: `SHIPOWNER_NAME`, key: `shipownerNAME`, sorter: { compare: (a,b) => null }, showSorterTooltip: false  },
                    { title: _t('Ações'), key: 'action', width: 180, render: (text, record) => (
                        <Row gutter={[4,4]} className="table__actions">
                            <Col><Button disabled={!verifyConfig(_RECOVER)} onClick={() => this.onRecover(record.key)} className="table__btn table__btn_recover" title={_t('Recuperar')} type="primary" shape="circle" size='small'><IoReturnUpBackOutline size={14} /></Button></Col>
                        </Row>
                        ),
                    },
                ]
            })
        })
    }

    onTableChange = (pagination, filters, sorter, extra) => {
        this.setState({
            table__filter: filters,
            table__sorter: sorter
        })
        this.onRenderData()
    }

    onPaginationChange = (page, size) => {
        this.setState({table__pagination: {...this.state.table__pagination, current: page, limit: size}})
        this.onRenderData()
    }

    onSearchChange = (e) => {
        this.setState({table__search: e.target.value})
        this.onRenderData()
    }

    onRenderData = () => {
        this.setState({table__load: true})
        setTimeout(async () => {
            POST_API('/operation/trash.php', { token: getToken(), pagination: JSON.stringify(this.state.table__pagination), filter: JSON.stringify(this.state.table__filter), sorter: JSON.stringify(this.state.table__sorter), search: this.state.table__search }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({table__data: res.data, table__pagination: { ...this.state.table__pagination, total: res.summary.QTDE }})
                } else {
                    message.error({ content: _p(res.msg), key: 'screen' })
                }
            }).catch(() => {
                message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
            }).finally(() => this.setState({table__load: false}))
        }, 500);
    }

    render = () => {

        const { Content } = Layout

        if (this.state._navigate) {
            return (<Navigate to={'/'} />)
        }

        if (getToken() == null || !verifyConfig(_TRASH)) {
            return (<Page403 />)
        }

        return (
            <Content className="sys-container">
                <Row gutter={[8,8]}>
                    <Col span={18}>
                        <Breadcrumb className="sys-breadcrumb">
                            <Breadcrumb.Item><Link to="/dashboard/operation">{_t('Operações')}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>{_t('Lixeira')}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Link to='/dashboard/operation'><Button type="primary" className="sys-btn-primary sys-btn-small">{_t('Voltar')}</Button></Link>
                    </Col>
                    <Col span={24}>
                        <Table
                            _columns={this.state.table__column}
                            _data={this.state.table__data}
                            _pagination={this.state.table__pagination}
                            _loading={this.state.table__load}
                            _onChangeTable={this.onTableChange}
                            _onChangePagination={this.onPaginationChange}
                            _onChangeSearch={this.onSearchChange}
                            _search={this.state.table__search}
                        />
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;