import { createTheme } from '@mui/material/styles';
import { getColor } from '../services/server-api';

export const principal = createTheme({
    palette: {
      primary: {
        main: getColor(),
      },
      secondary: {
        main: '#ffffff'
      }
    },
});

export const second = createTheme({
    palette: {
      primary: {
        main: '#ffffff',
      },
      secondary: {
        main: '#ffffff'
      }
    },
});