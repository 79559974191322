import React from "react";
import { Breadcrumb, Button, Col, Layout, message, Modal, Row } from 'antd';
import { Link, Navigate } from "react-router-dom";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { IoEye, IoPencil, IoTrash } from "react-icons/io5";

// API
import { getToken, POST_API, verifyConfig } from "../../../../services/server-api";

// TRANSLATE
import { _p, _t } from "../../../../services/language";

// COMPONENTS
import Table from '../../../../components/Widgets/Table';

// CONTROLLER
import Page403 from '../../../Controller/Page403';

// PERMISSIONS
import { _ADD, _DELETE, _EDIT, _LIST, _TRASH, _VIEW } from "./_permission";

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        _params: window.location.href.split('/'),
        // TABLE
        table__load: true,
        table__column: [],
        table__data: [],
        table__search: '',
        table__pagination: {
            current: 1,
            total: 0,
            limit: 10
        },
        table__filter: null,
        table__sorter: null
    }

    componentDidMount = () => {
        this.onRenderColumns().then(() => this.onRenderData())
    }

    onDelete = (ID) => {
        var self = this
        Modal.confirm({
            title: _t('Excluir registro?'),
            icon: <ExclamationCircleOutlined />,
            cancelText: _t('Não'),
            okText: _t('Sim'),
            className: 'sys-modal-confirm',
            onOk() {
                POST_API('/operation/port/del.php', { token: getToken(), ID: ID }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: _p(res.msg), key: 'screen' })
                        self.onRenderData()
                    } else {
                        message.warning({ content: _p(res.msg), key: 'screen' })
                    }
                }).catch(() => {
                    message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
                }).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
    }


    // FUNCTION TABLE
    onRenderColumns = async () => {
        this.setState({
            table__column: [
                { title: 'Cod.', dataIndex: 'ID', key: 'operation_port.ID', width: 100, align: 'center', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                { title: _t('Origem/Destino'), dataIndex: `POL_ID`, key: `operation_port.POL_ID`, sorter: { compare: (a,b) => null }, showSorterTooltip: false , render: (text, record) => (
                    <Row justify="center">
                        {record.POL_NAME} - {record.POD_NAME}
                    </Row>
                ) },
                { title: _t('Companhias'), dataIndex: `ASSEMBLER_NAME`, key: `assembler.NAME`, sorter: { compare: (a,b) => null }, showSorterTooltip: false, render: (text, record) => (
                    <Row justify="center">
                        {record.ASSEMBLER_NAME}/{record.EXPORT_NAME}
                    </Row>
                ) },
                { title: _t('Tipo'), dataIndex: `TYPE_NAME`, key: `operation_port.TYPE`, sorter: { compare: (a,b) => null }, showSorterTooltip: false, render: (text, record) => (
                    <Row justify="center">
                        {_t(record.TYPE_NAME)}
                    </Row>
                ) },
                { title: _t('Qtde.'), dataIndex: `QTD`, key: `operation_port.QTD`, sorter: { compare: (a,b) => null }, showSorterTooltip: false  },
                { title: _t('Ações'), key: 'action', width: 180, render: (text, record) => (
                    <Row gutter={[4,4]} className="table__actions">
                        <Col><Link to={verifyConfig(_VIEW) ? record.key : '#'}><Button disabled={!verifyConfig(_VIEW)} className="table__btn table__btn_view" title={_t('Visualizar')} type="primary" shape="circle" size="small"><IoEye size={14} /></Button></Link></Col>
                        <Col><Link to={verifyConfig(_EDIT) ? record.key+'/edit' : '#'}><Button disabled={!verifyConfig(_EDIT)} className="table__btn table__btn_edit" title={_t('Editar')} type="primary" shape="circle" size="small"><IoPencil size={14} /></Button></Link></Col>
                        <Col><Button disabled={!verifyConfig(_DELETE)} onClick={() => this.onDelete(record.key)} className="table__btn table__btn_delete" title={_t('Excluir')} type="primary" shape="circle" size="small"><IoTrash size={14} /></Button></Col>
                    </Row>
                    ),
                },
            ]
        })
    }

    onTableChange = (pagination, filters, sorter, extra) => {
        this.setState({
            table__filter: filters,
            table__sorter: sorter
        })
        this.onRenderData()
    }

    onPaginationChange = (page, size) => {
        this.setState({table__pagination: {...this.state.table__pagination, current: page, limit: size}})
        this.onRenderData()
    }

    onSearchChange = (e) => {
        this.setState({table__search: e.target.value})
        this.onRenderData()
    }

    onRenderData = () => {
        this.setState({table__load: true})
        this.setState({table__filter: { ...this.state.table__filter, OPERATION_ID: this.state._params[5]}})
        setTimeout(async () => {
            POST_API('/operation/port/search.php', { token: getToken(), pagination: JSON.stringify(this.state.table__pagination), filter: JSON.stringify(this.state.table__filter), sorter: JSON.stringify(this.state.table__sorter), search: this.state.table__search }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({table__data: res.data, table__pagination: { ...this.state.table__pagination, total: res.summary.QTDE }})
                } else {
                    message.error({ content: _p(res.msg), key: 'screen' })
                }
            }).catch(() => {
                message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
            }).finally(() => this.setState({table__load: false}))
        }, 500);
    }

    render = () => {

        const { Content } = Layout

        if (this.state._navigate) {
            return (<Navigate to={'/'} />)
        }

        if (getToken() == null || !verifyConfig(_LIST)) {
            return (<Page403 />)
        }

        return (
            <Content className="sys-container">
                <Row gutter={[8,8]}>
                    <Col span={18}>
                        <Breadcrumb className="sys-breadcrumb">
                            <Breadcrumb.Item><Link to="/dashboard/operation">{_t('Operações')}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>{this.state._params[5]}</Breadcrumb.Item>
                            <Breadcrumb.Item><Link to={`/dashboard/operation/${this.state._params[5]}/port/`}>{_t('Portos')}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>{_t('Lista')}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Link to={`/dashboard/operation`}><Button type="primary" className="sys-btn-primary sys-btn-small" style={{marginRight: 5}}>{_t('Voltar')}</Button></Link>
                        <Link to={verifyConfig(_TRASH) ? 'trash' : '#'}><Button disabled={!verifyConfig(_TRASH)} type="default" className="sys-btn-default sys-btn-small"style={{marginRight: 5}}>{_t('Lixeira')}</Button></Link>
                        <Link to={verifyConfig(_ADD) ? 'add' : '#'}><Button disabled={!verifyConfig(_ADD)} type="primary" className="sys-btn-primary sys-btn-small">{_t('Novo Porto')}</Button></Link>
                    </Col>
                    <Col span={24}>
                        <Table
                            _columns={this.state.table__column}
                            _data={this.state.table__data}
                            _pagination={this.state.table__pagination}
                            _loading={this.state.table__load}
                            _onChangeTable={this.onTableChange}
                            _onChangePagination={this.onPaginationChange}
                            _onChangeSearch={this.onSearchChange}
                            _search={this.state.table__search}
                        />
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;