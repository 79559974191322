import React from "react";
import { Button, Image } from 'antd';
import { IoLanguage } from 'react-icons/io5';

import PT_BR from 'bandeiras/svg/br.svg';
import EN_US from 'bandeiras/svg/us.svg';

import { setLanguage } from "../../services/language";

class Screen extends React.Component {

    state = {
        _translate: false
    }

    onTranslate = () => {
        this.setState({_translate: !this.state._translate})
    }

    render = () => {

        return (
            <>
                <Button onClick={this.onTranslate} type="text" className="sys-btn-text fab-button" shape="circle"><IoLanguage size={20} /></Button>
                <Button onClick={() => setLanguage('pt_br')} type="text" className={this.state._translate ? "sys-btn-text fab-button-br show" : "sys-btn-text fab-button-br hidden"} shape="circle"><Image src={PT_BR} preview={false} className="sys-bandeira" /></Button>
                <Button onClick={() => setLanguage('en_us')} type="text" className={this.state._translate ? "sys-btn-text fab-button-en show" : "sys-btn-text fab-button-en hidden"} shape="circle"><Image src={EN_US} preview={false} className="sys-bandeira" /></Button>
            </>
        )
    }

}

export default Screen;