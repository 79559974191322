import React from "react";
import { Col, Layout, Result, Row } from 'antd';
import Language from '../../components/Widgets/Language';
import { _t } from "../../services/language";

class Screen extends React.Component {

    render = () => {

        const { Content } = Layout

        return (
            <Content>
                <Row style={{height: '100vh', alignItems: 'center'}} justify="center">
                    <Col span={24}>
                        <Result
                            status="500"
                            title="500"
                            subTitle={_t("Erro no sistema! Por favor, entre em contato com o suporte.")}
                        />
                    </Col>
                </Row>
                <Language />
            </Content>
        )
    }

}

export default Screen;