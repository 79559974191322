import './App.css';
import React from 'react';
import AppStack from './routes/AppStack';

import { ThemeProvider } from '@mui/material/styles';


import { principal } from './themes/index';

class Screen extends React.Component {

  render = () => {

    return (
      <ThemeProvider theme={principal}>
        <AppStack />
      </ThemeProvider>
    )

  }

}

export default Screen;
