import React from "react";
import { Button, Card, Col, Divider, Form, Layout, message, Modal, Progress, Row, Select, Spin, Typography } from 'antd';
import { Link, Navigate } from "react-router-dom";

// API
import { getToken, POST_API } from "../../../services/server-api";

// TRANSLATE
import { getLanguageUpper, _p, _t } from "../../../services/language";

// CONTROLLER
import Page403 from '../../Controller/Page403';

import Scanner from "../../../components/Widgets/Scanner";

import Beep from "../../../assets/sounds/beep.mp3";

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        _screen: false,
        _modal: false,
        reader: false,
        _params: window.location.href.split('/'),
        defaultValue: {
            ID: 0
        },
        values: {
            coords: {latitude: 'Indisponível', longitude: 'Indisponível'},
            last: '-'
        },
        chassis: []
    }

    componentDidMount = () => {
        this.onView()
        this.loadOption()
    }

    GeoLocalSuccess = (value) => {
        this.setState({values: {...this.state.values, coords: value.coords}})
    }
    GeoLocalError = (value) => {
        this.setState({values: {...this.state.values, coords: {latitude: 'Indisponível', longitude: 'Indisponível'}}})
        message.error({ content: `ERROR(${value.code}): ${value.message}`, key: 'screen' })
    }

    loadOption = async () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.watchPosition(this.GeoLocalSuccess, this.GeoLocalError, {enableHighAccuracy: false, timeout: 3000, maximumAge: 0});
        } else {
            message.error({ content: _t('Geolocalização indisponível para o seu navegador.'), key: 'screen' })
            this.setState({values: {...this.state.values, coords: {latitude: 'Indisponível', longitude: 'Indisponível'}}})
        }
        await POST_API('/operation/cargo/search.php', { token: getToken(),  }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({values: {...this.state.values, TYPE_READING: res.data}})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
        await POST_API('/typereading/view.php', { token: getToken(), ID: this.state._params[8] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({values: {...this.state.values, TYPE_READING: res.data}})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
        await POST_API('/localreading/view.php', { token: getToken(), ID: this.state._params[7] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({values: {...this.state.values, LOCAL_READING: res.data}})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
        await POST_API('/subtypereading/view.php', { token: getToken(), ID: this.state._params[9] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({values: {...this.state.values, SUBTYPE_READING: res.data}})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        })
        await POST_API('/typecargo/view.php', { token: getToken(), ID: this.state._params[10] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({values: {...this.state.values, CARGO_TYPE: res.data}})
            } else {
                message.error({ content: _p(res.msg), key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
        }).finally(() => this.setState({_loading: false}))
    }

    onView = () => {
        var params = window.location.href.split('/')
        POST_API('/operation/view.php', { token: getToken(), ID: params[5] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: _p(res.msg), key: 'screen' })
                this.setState({_screen: true, defaultValue: res.data})
            } else {
                message.warning({ content: _p(res.msg), key: 'screen' })
                this.setState({_navigate: true})
            }
        }).catch(() => {
            message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
            this.setState({_navigate: true})
        })
    }

    onSend = async (values) => {
        this.setState({formvalues: values, _navigate: true})
    }

    onDetected = (result) => {

        if (!this.state.reader) {

            var dados = {
                OPERATION_ID: this.state._params[5],
                LOCAL_READING_ID: this.state._params[7],
                TYPE_READING_ID: this.state._params[8],
                SUBTYPE_READING_ID: this.state._params[9],
                TYPE_CARGO_ID: this.state._params[10],
                CHASSI: result,
                LATITUDE: this.state.values.coords.latitude,
                LONGITUDE: this.state.values.coords.longitude,
            }
            POST_API('/operation/cargo/collect.php', { token: getToken(), ...dados }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    var audio = new Audio(Beep)
                    audio.play();
                    message.success({ content: _p(res.msg), key: 'screen' })
                    this.setState({reader: res.data.ID})
                    setTimeout(() => {
                        this.setState({_navigate: true})
                    }, 1000)
                } else {
                    // message.warning({ content: _p(res.msg), key: 'screen' })
                }
            }).catch(() => {
                message.error({ content: _t('Não foi possível estabelecer uma conexão com o sistema'), key: 'screen' })
            }).finally(() => {})
        }
         
    }

    render = () => {

        const { Content } = Layout

        if (this.state._navigate) {
            return (<Navigate to={`/dashboard/inspector/${this.state._params[5]}/collect/${this.state._params[7]}/${this.state._params[8]}/${this.state._params[9]}/${this.state._params[10]}/${this.state.reader}`} />)
        }

        if (getToken() == null) {
            return (<Page403 />)
        }

        return (
            <Content className="sys-container">
                <Row gutter={[8,8]}>
                    <Col span={24}>
                        <Typography className="sys-title">- {_t('Leitor')} -</Typography>
                    </Col>
                    <Col span={24}>
                        <Scanner onDetected={this.onDetected} />
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;